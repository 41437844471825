import capitalize from 'lodash/capitalize';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';

import { isThirdParty } from '@lumapps/search/utils';

/////////////////////////////

function DataSourceItemController(
    CloudSearchConstant,
    CloudSearchDataSourceConfig,
    FormValidation,
    LxNotificationService,
    Translation,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The data source before modifications.
     *
     * @type {Object}
     */
    let _originalDataSource;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Defines whether the accordion is closed or not.
     *
     * @type {boolean}
     */
    vm.isAccordionClosed = true;

    /**
     * Indicates if the custom fields displayed is displayed or not.
     *
     * @type {boolean}
     */
    vm.showCustomFieldsDialog = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.CloudSearchConstant = CloudSearchConstant;
    vm.FormValidation = FormValidation;
    vm.Translation = Translation;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Set the formatted data source kind to be displayed in the list.
     */
    function _setFormattedKind() {
        if (!isThirdParty(vm.datasource.kind)) {
            vm.formattedKind = capitalize(vm.datasource.kind);

            return;
        }

        vm.formattedKind = Translation.translate('ADMIN.DATA_SOURCE.THIRD_PARTY');
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add the custom fields selected in the dialog to the fields already configured for the data source.
     *
     * @param {Array} customFields A list of custom fields to add to the data source configuration.
     */
    function addCustomFields(customFields) {
        if (angular.isUndefinedOrEmpty(customFields)) {
            return;
        }

        vm.datasource.customFields = vm.datasource.customFields.concat(customFields);
        vm.datasourceDetailsForm.$setDirty();
    }

    /**
     * Cancel changes that have been made to the data source since opening the accordion.
     *
     * @param {Event} evt The click event on the cancel button that triggers this method.
     */
    function cancelChanges(evt) {
        vm.isAccordionClosed = true;
        vm.datasource = angular.fastCopy(_originalDataSource);
        vm.datasourceDetailsForm.$setPristine();

        evt.preventDefault();
        evt.stopPropagation();
    }

    /**
     * Close the dialog that allows to add custom fields to a 3p data source.
     *
     * Note: we only set the flag to false here because we want the dialog to keep some temporary data while the
     * data source settings have not been saved (i.e. tmpCustomFields).
     */
    function closeCustomFieldsDialog() {
        if (!isThirdParty(vm.datasource.kind)) {
            return;
        }

        vm.showCustomFieldsDialog = false;
    }

    /**
     * Open the dialog that allows to add custom fields to a 3p data source.
     */
    function openCustomFieldsDialog() {
        if (!isThirdParty(vm.datasource.kind)) {
            return;
        }

        vm.showCustomFieldsDialog = true;

        Utils.waitForAndExecute(`#${vm.CUSTOM_FIELDS_DIALOG_ID}`);
    }

    /**
     * Remove a given custom field from the list of custom fields for the data source.
     *
     * @param {Object} customField The custom field to remove from the list.
     */
    function removeCustomField(customField) {
        if (angular.isUndefinedOrEmpty(customField)) {
            return;
        }

        Utils.reject(vm.datasource.customFields, {
            name: customField.name,
        });

        vm.datasourceDetailsForm.$setDirty();
    }

    /**
     * Save the CloudSearch configuration of a data source.
     */
    function saveDataSourceConfig() {
        const currentConfig = CloudSearchDataSourceConfig.getCurrent(CloudSearchConstant.LIST_KEY.DATASOURCES);

        // Order the fields the way they are in the UI.
        vm.datasource.customFields = map(vm.datasource.customFields, (field, index) => {
            field.order = index;

            return field;
        });

        CloudSearchDataSourceConfig.save(
            {
                customerId: currentConfig.customerId,
                items: [vm.datasource],
            },
            function onDataSourceConfigSave() {
                const datasourceIndex = findIndex(currentConfig.items, {
                    uid: vm.datasource.uid,
                });

                if (angular.isUndefinedOrEmpty(datasourceIndex)) {
                    return;
                }

                currentConfig.items[datasourceIndex] = vm.datasource;
                CloudSearchDataSourceConfig.setCurrent(currentConfig, CloudSearchConstant.LIST_KEY.DATASOURCES);

                // Update the original in case we want to revert to it later.
                _originalDataSource = angular.fastCopy(vm.datasource);
                vm.originalDataSourceCustomFields = _originalDataSource.customFields;

                vm.isAccordionClosed = true;

                LxNotificationService.success(Translation.translate('GLOBAL.SAVED'));
            },
            Utils.displayServerError,
            CloudSearchConstant.LIST_KEY.DATASOURCES,
        );
    }

    /**
     * Set the status of the datasource to a given new status.
     *
     * @param {boolean} enabled Indicates if we want to enable the current datasource or not.
     */
    function setStatus(enabled) {
        vm.datasource.enabled = enabled;

        vm.saveDataSourceConfig();
    }

    /**
     * Update a given custom field value.
     * This is called by the React component when the CustomField is changed.
     *
     * @param {Object} customField   A custom field to be updated in the current data source.
     * @param {string} localizedName The new value of the CustomField label.
     */
    function updateCustomField({ customField = {}, changes = {} }) {
        if (angular.isUndefinedOrEmpty(customField)) {
            return;
        }

        const position = findIndex(vm.datasource.customFields, { name: customField.name });
        vm.datasource.customFields[position] = { ...vm.datasource.customFields[position], ...changes };

        vm.datasourceDetailsForm.$setDirty();
    }

    /**
     * Update the list of custom fields.
     * This is called by the React component when the list is reordered.
     *
     * @param {Array} items A custom field to be updated in the current data source.
     */
    function updateCustomFieldList({ customFields = [] }) {
        if (angular.isUndefinedOrEmpty(customFields)) {
            return;
        }

        vm.datasource.customFields = angular.fastCopy(customFields);

        vm.datasourceDetailsForm.$setDirty();
    }

    /////////////////////////////

    vm.addCustomFields = addCustomFields;
    vm.cancelChanges = cancelChanges;
    vm.closeCustomFieldsDialog = closeCustomFieldsDialog;
    vm.isThirdParty = isThirdParty;
    vm.openCustomFieldsDialog = openCustomFieldsDialog;
    vm.removeCustomField = removeCustomField;
    vm.saveDataSourceConfig = saveDataSourceConfig;
    vm.setStatus = setStatus;
    vm.updateCustomField = updateCustomField;
    vm.updateCustomFieldList = updateCustomFieldList;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        vm.datasource.customFields = vm.datasource.customFields || [];

        _originalDataSource = angular.fastCopy(vm.datasource);
        vm.originalDataSourceCustomFields = _originalDataSource.customFields;

        vm.CUSTOM_FIELDS_DIALOG_ID = `custom-fields-dialog-${vm.datasource.uid}`;
        vm.ICON_CLASSNAME = `mdi-${CloudSearchDataSourceConfig.getDataSourceMDIName(vm.datasource)}`;

        _setFormattedKind();
    }

    init();
}

/////////////////////////////

/**
 * Data Source Item directive.
 * Display a data source item block in the cloudsearch administration.
 *
 * @param  {Object} datasource The datasource to display the details of.
 * @return {Object} A data source item directive.
 */
function DataSourceItemDirective() {
    'ngInject';

    return {
        bindToController: true,
        controller: DataSourceItemController,
        controllerAs: 'vm',
        restrict: 'E',
        scope: {
            datasource: '<lsDataSource',
        },
        templateUrl: '/client/back-office/modules/customer/views/data-source-item.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('lsDataSourceItem', DataSourceItemDirective);

/////////////////////////////

export { DataSourceItemController, DataSourceItemDirective };
