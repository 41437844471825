import { BACK_OFFICE } from './app';

/////////////////////////////

/**
 * The default startup of the Back-Office application.
 */
function BackOfficeRun() {
    'ngInject';
}

///////////////////////////////

BACK_OFFICE.run(BackOfficeRun);
