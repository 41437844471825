import includes from 'lodash/includes';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import loFind from 'lodash/find';
import values from 'lodash/values';
import { angularApi } from '@lumapps/router/routers';

import { isReactUserDirectory } from '@lumapps/user-directory-front-office/ducks/selectors';
import { userDirectoryView } from '@lumapps/user-directory-front-office/routes';

/////////////////////////////

function ModuleAdminController(
    $rootScope,
    $scope,
    $state,
    $sanitize,
    Community,
    Config,
    ConfigInstance,
    ConfigTheme,
    Content,
    ContentForm,
    CustomContentType,
    Directory,
    Features,
    InitialSettings,
    Instance,
    LxDataTableService,
    LxDropdownService,
    LxNotificationService,
    ModuleAdmin,
    Translation,
    Tutorial,
    User,
    UserAccess,
    Utils,
    ReduxStore,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////
    // This stub is left because there is a debounce private function declared below. So please do not delete.

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The dropdown filter identifier.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.DROPDOWN_ID = 'admin-filters';

    /**
     * The dropdown filter opening target.
     *
     * @type {string}
     * @constant
     * @readonly;
     */
    vm.DROPDOWN_TARGET = 'admin-search-field';

    /**
     * The list key for the list of modules.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.LIST_KEY = 'module-admin';

    /**
     * The headers of the tables that contains the list of modules.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    vm.MODULES_DATA_TABLE_HEADERS = [
        {
            format: function formatModuleName(row) {
                return $sanitize(Translation.translate(row.title));
            },
            label: Translation.translate('ADMIN_MODULE_NAME'),
            name: 'name',
        },
        {
            format: function formatType(row) {
                return Translation.translate(`CONTENT_TYPE_${row.type.toUpperCase()}`);
            },
            label: Translation.translate('ADMIN_MODULE_TYPE'),
            name: 'type',
        },
        {
            format: function formatAuthorName(row) {
                return row.authorDetails
                    ? User.getUserFullName(row.authorDetails)
                    : Translation.translate('UNKNOWN_USER');
            },
            label: Translation.translate('AUTHOR'),
            name: 'author',
        },
    ];

    /**
     * The id of the "modules" data table.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.MODULES_DATA_TABLE_ID = 'modulesDataTable';

    /**
     * Contains the list of available modules.
     *
     * @type {Array}
     */
    vm.availableModules = [];

    /**
     * Alias to the CONTENT_TYPES constant.
     *
     * @type {Array}
     */
    vm.contentTypes = InitialSettings.CONTENT_TYPES;

    /**
     * Contains the search filters.
     *
     * @type {Object}
     */
    vm.filters = {
        name: undefined,
        type: [],
    };

    /**
     * Contains the selected modules.
     *
     * @type {Array}
     */
    vm.selectedModules = [];

    /////////////////////////////

    /*
     * Services and utilities.
     */
    vm.Config = Config;
    vm.ConfigInstance = ConfigInstance;
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.CustomContentType = CustomContentType;
    vm.ModuleAdmin = ModuleAdmin;
    vm.Translation = Translation;
    vm.UserAccess = UserAccess;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Unselect all rows in the data table and show a deletion success notification to the user.
     *
     * @param {string} [transKey='ADMIN_MODULE_MODULE_DELETE_SUCCESS'] The translation key to use for the
     *                                                                 notification success message.
     */
    function _unselectAndNotify(transKey) {
        transKey = transKey || 'ADMIN_MODULE_MODULE_DELETE_SUCCESS';

        LxDataTableService.unselectAll(vm.MODULES_DATA_TABLE_ID);
        LxNotificationService.success(Translation.translate(transKey));
    }
    vm.Features = Features;

    /**
     * Delete a given module.
     *
     * @param {Object} existingModule The module to be deleted.
     *
     * TODO [Arnaud]: Get rid of this and just use the deleteMulti. It does the same thing I think...
     */
    function _deleteModule(existingModule) {
        if (angular.isUndefinedOrEmpty(existingModule)) {
            return;
        }

        LxNotificationService.confirm(
            Translation.translate('ADMIN_MODULE_MODULE_DELETE'),
            Translation.translate('ADMIN_MODULE_MODULE_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onModuleDeleteConfirm(answer) {
                if (!answer) {
                    return;
                }

                let Service;
                let key;

                switch (existingModule.type) {
                    case Config.AVAILABLE_CONTENT_TYPES.COMMUNITY:
                        Service = Community;
                        key = existingModule.uid;

                        break;

                    case Config.AVAILABLE_CONTENT_TYPES.TUTORIAL:
                        Service = Tutorial;
                        key = existingModule.externalKey;

                        break;

                    default:
                        Service = Content;
                        key = existingModule.uid;

                        break;
                }

                Service.del(
                    key,
                    function onContentDeleteSuccess() {
                        switch (existingModule.type) {
                            case Config.AVAILABLE_CONTENT_TYPES.USER_DIRECTORY: {
                                const idx = findIndex(InitialSettings.USER_DIRECTORIES, {
                                    id: existingModule.id,
                                });

                                if (idx > -1) {
                                    InitialSettings.USER_DIRECTORIES = InitialSettings.USER_DIRECTORIES.splice(idx, 1);
                                    LxDataTableService.unselectAll(vm.MODULES_DATA_TABLE_ID);
                                }

                                const userDirectory = Instance.getInstance().defaultUserDirectory;

                                if (userDirectory === existingModule.id) {
                                    Instance.getInstance().defaultUserDirectory = undefined;
                                }

                                return;
                            }
                            case Config.AVAILABLE_CONTENT_TYPES.COMMUNITY:
                            case Config.AVAILABLE_CONTENT_TYPES.TUTORIAL:
                                _unselectAndNotify();

                                Utils.reject(Content.displayList(vm.LIST_KEY), {
                                    uid: existingModule.uid,
                                });

                                return;

                            default:
                                _unselectAndNotify();
                        }
                    },
                    Utils.displayServerError,
                    vm.LIST_KEY,
                );
            },
        );
    }

    /**
     * Filter the modules list according to the user's filters.
     */
    function _filterModules() {
        LxDataTableService.unselectAll(vm.MODULES_DATA_TABLE_ID);

        Content.filterize(
            {
                action: 'CUSTOM_EDIT',
                excludeType: [
                    Config.AVAILABLE_CONTENT_TYPES.COMMUNITY,
                    Config.AVAILABLE_CONTENT_TYPES.CUSTOM,
                    Config.AVAILABLE_CONTENT_TYPES.CUSTOM_LIST,
                    Config.AVAILABLE_CONTENT_TYPES.IMAGE_GALLERY,
                    Config.AVAILABLE_CONTENT_TYPES.MENU,
                    Config.AVAILABLE_CONTENT_TYPES.NEWS,
                    Config.AVAILABLE_CONTENT_TYPES.NEWS_LIST,
                    Config.AVAILABLE_CONTENT_TYPES.PAGE,
                    Config.AVAILABLE_CONTENT_TYPES.POST,
                ],
                query: vm.filters.name,
                type: vm.filters.type,
            },
            undefined,
            undefined,
            vm.LIST_KEY,
        );
    }

    /**
     * Update the selected modules list when a row is toggled in the data table.
     *
     * @param {Event}  evt          The select/unselect event from the data table.
     * @param {string} dataTableId  The data table identifier.
     * @param {Array}  selectedRows The selected rows.
     */
    function _updateSelectedModules(evt, dataTableId, selectedRows) {
        if (dataTableId === vm.MODULES_DATA_TABLE_ID) {
            vm.selectedModules = selectedRows;
        }
    }

    /**
     * Select/unselect a module on row activation.
     *
     * @param {Event}  evt          The activate event from the data table.
     * @param {string} dataTableId  The data table identifier.
     * @param {Array}  activatedRow The activated row.
     */
    function _updateSelectedModulesOnActivate(evt, dataTableId, activatedRow) {
        if (dataTableId !== vm.MODULES_DATA_TABLE_ID) {
            return;
        }

        // Hack waiting inline actions.
        LxDataTableService.deactivate(dataTableId, activatedRow);

        for (let i = 0, len = vm.selectedModules.length; i < len; i++) {
            if (vm.selectedModules[i].uid === activatedRow.uid) {
                LxDataTableService.unselect(dataTableId, activatedRow);

                return;
            }
        }

        LxDataTableService.select(dataTableId, activatedRow);
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Delete selected modules.
     */
    function deleteSelectedModules() {
        if (angular.isUndefinedOrEmpty(vm.selectedModules)) {
            return;
        }

        if (vm.selectedModules.length === 1) {
            _deleteModule(vm.selectedModules[0]);
            return;
        }

        const deletableItems = [];
        const directoryDeletableItems = [];

        angular.forEach(vm.selectedModules, function forEachSelectedModules(selectedModule) {
            deletableItems.push(selectedModule.id);

            if (selectedModule.type === Config.AVAILABLE_CONTENT_TYPES.DIRECTORY) {
                directoryDeletableItems.push(selectedModule.externalKey);

                return;
            }

            if (selectedModule.type !== Config.AVAILABLE_CONTENT_TYPES.USER_DIRECTORY) {
                return;
            }

            const currentInstance = Instance.getInstance();

            Utils.reject(InitialSettings.USER_DIRECTORIES, {
                id: selectedModule.id,
            });

            if (currentInstance.defaultUserDirectory === selectedModule.id) {
                currentInstance.defaultUserDirectory = undefined;
            }
        });

        LxNotificationService.confirm(
            Translation.translate('ADMIN_DELETE_SELECTED_ITEMS'),
            Translation.translate('ADMIN_DELETE_SELECTED_ITEMS_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onDeleteSelectedRowsButtonClick(answer) {
                if (!answer) {
                    return;
                }

                Content.delMulti(deletableItems, undefined, Utils.displayServerError, vm.LIST_KEY);
            },
        );
    }

    /**
     * Check if the user is authorized to edit or delete the selected modules.
     * If at least one module is not authorized for the user, then we disallow the global action.
     *
     * @param  {Array}   selectedModules The selected modules.
     * @param  {string}  actionType      The type of action we want to check.
     *                                   Possible values are: "EDIT" or "DELETE".
     * @return {boolean} If the current user is authorized to edit or delete all the selected modules.
     */
    function isAuthorized(selectedModules, actionType) {
        const unauthorizedModule = loFind(selectedModules, function findUnauthorizedModule(selectedModule) {
            return !UserAccess.isUserAllowed(`${selectedModule.type.toUpperCase()}_${actionType}`);
        });

        return angular.isUndefinedOrEmpty(unauthorizedModule);
    }

    /**
     * Open filters.
     *
     * @param {Event} evt The click event.
     */
    function openFilters(evt) {
        LxDropdownService.open(vm.DROPDOWN_ID, `#${vm.DROPDOWN_TARGET}`);

        evt.stopPropagation();
    }

    /**
     *  isReactUserDirectoryEnabled takes the status of user-directory-react feature flag
     */
    const state = ReduxStore.store.getState();
    $scope.isReactUserDirectoryEnabled = isReactUserDirectory(state);

    /**
     *  Redirect user to the correct state according to the module he wants to see.
     */
    function redirect() {
        const target = first(vm.selectedModules);

        if (angular.isUndefinedOrEmpty(target)) {
            return;
        }
        if($scope.isReactUserDirectoryEnabled){
             angularApi.redirect(
             userDirectoryView({ contentId: target.uid, slug: Translation.translate(target.slug) }),
        );
        }
        else {
            $state.go('app.front.content-get', {
                slug: Translation.translate(target.slug),
            });
        }

    }

    /////////////////////////////

    vm.deleteSelectedModules = deleteSelectedModules;
    vm.isAuthorized = isAuthorized;
    vm.openFilters = openFilters;
    vm.redirect = redirect;

    /////////////////////////////
    //                         //
    //        Watchers         //
    //                         //
    /////////////////////////////

    /**
     * Watch for any changes in the filters.
     * Refresh the list of modules according to the filters.
     * Debounce the search so that we wait for some time before launching the search.
     *
     * @param {Object} newFilters The new filters.
     * @param {Object} oldFilters The previous filters.
     */
    $scope.$watch(
        'vm.filters',
        function filtersWatch(newFilters, oldFilters) {
            if (newFilters === oldFilters) {
                return;
            }

            _filterModules();
        },
        true,
    );

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the module dialog closes, reset the services, stop the watchers and unselect all selected modules.
     *
     * @param {Event}  evt      The dialog close event.
     * @param {string} dialogId The id of the dialog that closes.
     */
    $scope.$on('lx-dialog__close-end', function onDialogCloseEnd(evt, dialogId) {
        // In case the event is triggered and the dialog key do not match any available dialog key.
        // For example, nested modals.
        if (!includes(values(ModuleAdmin.dialogKeys), dialogId)) {
            return;
        }

        if (dialogId === ModuleAdmin.dialogKeys.directory) {
            Directory.setCurrent(undefined);
        } else {
            Content.setCurrent(undefined, ModuleAdmin.getListKey());
            ContentForm.resetContentForm();
        }

        ModuleAdmin.emptyContent();
        LxDataTableService.unselectAll(vm.MODULES_DATA_TABLE_ID);
    });

    /**
     * When a row is selected in the data table, update the selected modules.
     */
    $scope.$on('lx-data-table__selected', _updateSelectedModules);

    /**
     * When a row is deselected in the data table, update the selected modules.
     */
    $scope.$on('lx-data-table__unselected', _updateSelectedModules);

    /**
     * When a row is activated in the data table, update the selected modules.
     */
    $scope.$on('lx-data-table__activated', _updateSelectedModulesOnActivate);

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const possibleModules = [
            Config.AVAILABLE_CONTENT_TYPES.DIRECTORY,
            Config.AVAILABLE_CONTENT_TYPES.USER_DIRECTORY,
            Config.AVAILABLE_CONTENT_TYPES.TUTORIAL,
        ];

        angular.forEach(possibleModules, function forEachModuleType(moduleType) {
            if (includes(ConfigInstance.AVAILABLE_MODULES, moduleType)) {
                vm.availableModules.push(moduleType);
            }
        });

        // We want to be able to add a community but we don't the community type in the filter types.
        vm.filters.type = angular.copy(vm.availableModules);

        _filterModules();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('ModuleAdminController', ModuleAdminController);

export { ModuleAdminController };
