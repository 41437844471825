/**
 * Map of all supported user sync field type.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const UserSyncTypeConstant = {
    BOOL: 'bool',
    TEXT: 'text',
};
/////////////////////////////

angular.module('Constants').constant('UserSyncTypeConstant', UserSyncTypeConstant);

/////////////////////////////

export { UserSyncTypeConstant };
