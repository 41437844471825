import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';

/////////////////////////////

function GlobalSettingsService(AdvancedSettingsConstant, Features, Translation, Utils) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = {};

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Available types.
     *
     * @type {Object}
     */
    service.types = {
        boolean: 'boolean',
        collection: 'collection',
        dragAndDrop: 'drag-and-drop',
        input: 'input',
        language: 'language',
        select: 'select',
        textarea: 'textarea',
    };

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * A cheaper version of indexOf.
     *
     * @param  {string} str    The string to look into.
     * @param  {number} offset Where to start to search for the needle.
     * @param  {string} needle The searched character.
     * @return {number} Position of the searched char or -1 if not found.
     *
     * Todo: get rid of this. We have libs for this kind of things!!!
     */
    function _cheapIndexOf(str, offset, needle) {
        let j = 0;

        while (offset + j <= str.length) {
            if (str.charAt(offset + j) === needle) {
                return j;
            }
            j++;
        }

        return -1;
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * This is a model formatter function, this function is called with custom input type 'array' at init it take
     * the value of the model and set the $viewValue with a formatted string (the value that is displayed).
     *
     * @param  {Array}  value The value to join.
     * @return {string} Joined array.
     */
    function arrayToString(value) {
        if (angular.isArray(value)) {
            return value.join(', ');
        }

        return value;
    }

    /**
     * Build the settings contents split by category.
     *
     * @param {Object}  propertiesConfig The Object that will be hydrated that categorise properties.
     * @param {Service} featuresService  The feature service.
     */
    function buildConfig(propertiesConfig, featuresService) {
        const options = cloneDeep(AdvancedSettingsConstant.OPTIONS);

        angular.forEach(options, function buildSetting(value, key) {
            // Check if has a category the category is defined in settings else add element to default category.
            const cat =
                has(value, 'category') && AdvancedSettingsConstant.CATEGORIES[value.category]
                    ? value.category
                    : 'default';

            if (!angular.isObject(propertiesConfig[cat])) {
                propertiesConfig[cat] = {};
            }

            // Categorise element.
            propertiesConfig[cat][key] = value;
        });
    }

    /**
     * Format a string.
     *
     * @param  {string} str The string to format.
     * @return {string} The formatted string.
     */
    function formatSearchString(str) {
        return str ? str.toLowerCase() : '';
    }

    /**
     * Search for approximate pattern in string.
     * Took from 'a8m/angular-filter_common.js'.
     *
     * @param  {string}  query   The query to search.
     * @param  {string}  pattern The fuzzy search pattern.
     * @return {boolean} If found or not in the query.
     */
    function fuzzyMatch(query, pattern) {
        let offset = 0;

        for (let i = 0; i <= pattern.length; i++) {
            const index = _cheapIndexOf(query, offset, pattern.charAt(i));

            if (index === -1) {
                return false;
            }

            offset += index + 1;
        }

        return true;
    }

    /**
     * Get original settings configuration.
     *
     * @param  {string} key The identifier of the property.
     * @return {Object} The original configuration.
     */
    function getConfiguration(key) {
        return Utils.getProperty(key, AdvancedSettingsConstant.OPTIONS, '');
    }

    /**
     * Perform a local fuzzy search.
     *
     * @param  {string} query      The text query for filtering.
     * @param  {Object} properties The properties to search into.
     * @return {Object} An object with filtered properties.
     */
    function search(query, properties) {
        const result = {};

        // By default (currently) the search is formatted so case un-sensitive.
        query = formatSearchString(query);

        // Iter over category.
        angular.forEach(properties, function iterOverCategory(elem, catName) {
            const subElement = {};
            let empty = true;

            // Iter over properties.
            angular.forEach(elem, function iterOverProperties(prop, elementName) {
                // If fuzzy search match, add it to object.
                // TODO [Maxime]: remove Translation.translate(elementName) and compute it at init.
                const formattedString = formatSearchString(Translation.translate(elementName));
                if (fuzzyMatch(formattedString, query)) {
                    subElement[elementName] = prop;
                    empty = false;
                }
            });

            // If subElement has any element, add to filtered properties.
            if (!empty) {
                result[catName] = subElement;
            }
        });

        return result;
    }

    /**
     * Called each time the model of custom input type 'array' is modified it transform value typed by user into an
     * array (so the $modelValue).
     *
     * @param  {string} value The string to split.
     * @return {Array}  The split value.
     */
    function stringToArray(value) {
        return value.split(',').map(function strTrim(str) {
            return str.trim();
        });
    }

    /////////////////////////////

    service.arrayToString = arrayToString;
    service.buildConfig = buildConfig;
    service.formatSearchString = formatSearchString;
    service.fuzzyMatch = fuzzyMatch;
    service.getConfiguration = getConfiguration;
    service.search = search;
    service.stringToArray = stringToArray;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('GlobalSettings', GlobalSettingsService);

/////////////////////////////

export { GlobalSettingsService };
