(function IIFE() {
    'use strict';

    /////////////////////////////

    function CloudSearchSettingsController(CloudSearchConstant, CloudSearchDataSourceConfig, Customer, Utils) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.CloudSearchConstant = CloudSearchConstant;
        vm.CloudSearchDataSourceConfig = CloudSearchDataSourceConfig;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Initialize the CloudSearch data sources for the current customer.
         */
        function _initCloudSearchDataSourceConfig() {
            CloudSearchDataSourceConfig.get({
                customerId: Customer.getCustomerId(),
                ignore403: true,
            }, undefined, Utils.displayServerError, CloudSearchConstant.LIST_KEY.DATASOURCES);
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            _initCloudSearchDataSourceConfig();
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('CloudSearchSettingsController', CloudSearchSettingsController);
})();
