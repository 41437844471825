import { GA4_MAX_ATTR_NAME_SIZE, GA4_USER_PROP_PREFIX } from './constants';
import { ConvertedGA4Attributes } from './types';

/**
 * Validate Google Analytics code.
 *
 * @param  {string}  code The Analytics code to validate.
 * @return {boolean} Whether the Google Analytics code is valid.
 */
export const isGAValid = (code?: string) => {
    const GA_REGEXP = /^UA-[0-9]+-[0-9]+|G-[A-Z0-9]{10}$/;

    if (!code || code.trim() === '') {
        return false;
    }

    return GA_REGEXP.test(code);
};

/**
 * Validate Google Tag Manager code.
 *
 * @param  {string}  code The Tag Manager code to validate.
 * @return {boolean} If the Google Tag Manager code is valid.
 */
export const isGTMValid = (code?: string) => {
    const GTM_REGEXP = /^GTM-[A-Z0-9]+$/;

    if (!code || code.trim() === '') {
        return false;
    }

    return GTM_REGEXP.test(code);
};

export const getCleanedName = (lumappsName: string) => {
    return lumappsName.toLowerCase().replace(/[^a-zA-Z0-9_-]/g, '');
};

/**
 * @param lumappsName Name like lumapps_user_dir or lumapps_
 * @returns normalized name
 */
export const getAttributeNameForGA4 = (lumappsName: string): string => {
    return getCleanedName(lumappsName)
        .replace('lumapps_user_dir', GA4_USER_PROP_PREFIX)
        .replace(/[-]/g, '_')
        .replace('lumapps_', 'la_')
        .slice(0, GA4_MAX_ATTR_NAME_SIZE);
};

/** Convert attributes to reduce size because GA4 attributes names are limited in size */
export const convertAttributesForGA4 = (attributes: object): ConvertedGA4Attributes => {
    return Object.fromEntries(Object.entries(attributes).map(([key, val]) => [getAttributeNameForGA4(key), val]));
};
