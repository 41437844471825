import reduce from 'lodash/reduce';

function IdentityProviderService($q, IdentityProviderFactory, LumsitesBaseService) {
    'ngInject';

    const service = LumsitesBaseService.createLumsitesBaseService(IdentityProviderFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The fields we want to retrieve when listing users count by providers.
     *
     * @type {Object}
     * @constant
     * @readonly
     */
    const _USERS_COUNT_PROJECTION = {
        items: {
            nbUsers: true,
            type: true,
        },
    };
    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Makes a request to delete an identity provider.
     *
     * @param  {string} customerId Identifier of customer.
     * @param  {string} uuid       Identifier of identity provider.
     * @return {Object} Promise of deletion request.
     */
    function deleteProvider(customerId, uuid) {
        return IdentityProviderFactory.delete({
            customerId,
            uuid,
        }).$promise;
    }

    /**
     * Makes a request to get an identity provider.
     *
     * @param  {string} customerId Identifier of customer.
     * @param  {string} uuid       The provider uuid to get.
     * @return {Object} Promise of the get request.
     */
    function get(customerId, uuid) {
        return IdentityProviderFactory.get({
            customerId,
            uuid,
        }).$promise;
    }

    /**
     * Get the number of users of a given source type across all sources.
     *
     * @param  {string} customerId The identifier of the customer we want to list the providers of.
     * @return {Object} Promise that will be resolved with count object.
     */
    function getUsersCount(customerId) {
        return $q((resolve, reject) => {
            const params = service.computeProjection(
                {
                    customerId,
                },
                _USERS_COUNT_PROJECTION,
            );

            service
                .list(params)
                .then((results) => {
                    resolve(
                        reduce(
                            results,
                            (obj, current) => {
                                if (angular.isUndefined(obj[current.type])) {
                                    obj[current.type] = 0;
                                }
                                obj[current.type] += current.nbUsers;

                                return obj;
                            },
                            {},
                        ),
                    );
                })
                .catch(reject);
        });
    }

    /**
     * Get the XML data corresponding of the Service Provider file for SAML provider.
     *
     * @param  {string} customerId The customer identifier.
     * @param  {string} ctx        The SAML unique reference.
     * @return {Object} Promise of the get request.
     */
    function getServiceProviderFile(customerId, ctx) {
        return IdentityProviderFactory.getSpFile({
            ctx,
            customerId,
        }).$promise;
    }

    /**
     * Makes a request to list all identity providers.
     *
     * @param  {Object} [params] Optional parameters to add to the request.
     * @return {Object} Promise of the list request.
     */
    function list(params) {
        return $q((resolve, reject) => {
            IdentityProviderFactory.list(params)
                .$promise.then((response) => {
                    resolve(response.items);
                })
                .catch(reject);
        });
    }

    /**
     * Makes a request to create/update an identity provider.
     *
     * @param  {Object} provider Identity provider to be created/updated.
     * @return {Object} Promise of the save request.
     */
    function saveProvider(provider) {
        return IdentityProviderFactory.save(provider).$promise;
    }

    /////////////////////////////

    service.delete = deleteProvider;
    service.get = get;
    service.getServiceProviderFile = getServiceProviderFile;
    service.getUsersCountByProviderType = getUsersCount;
    service.list = list;
    service.save = saveProvider;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('IdentityProvider', IdentityProviderService);

export { IdentityProviderService };
