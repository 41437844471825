import get from 'lodash/get';
import set from 'lodash/set';
import map from 'lodash/map';
import includes from 'lodash/includes';

import { generateUUID } from '@lumapps/utils/string/generateUUID';
import { handleNannyError, shouldUseNannyErrorHandler } from '@lumapps/nanny-details/utils/errorHandler';
import { angularApi } from '@lumapps/router/routers';
import { adminContentTemplates } from '@lumapps/content-templates/routes';
import { saveStructuredContentFromWidget } from '@lumapps/widget-contribution/ducks/thunks/saveStructuredContentFromWidget';
import { getImageForThumbnail } from '@lumapps/lumx-images/utils';
import { canAccessBroadcast } from '@lumapps/broadcasts/ducks/selectors';
import { showContentPublishedDistributeNotfication } from '@lumapps/distribute/ducks/thunks';
import { CONTENTS } from '@lumapps/contents/keys';
import { contentView } from '@lumapps/contents/routes';

// ///////////////////////////

function ContentSidebarSettingsController(
    $rootScope,
    $scope,
    $state,
    $stateParams,
    $timeout,
    Community,
    CommunityTemplate,
    CommunityTemplates,
    CommunityWizardConfiguration,
    Config,
    ConfigTheme,
    Content,
    ContentForm,
    ContentTemplate,
    CustomContentType,
    Directory,
    Document,
    FeaturedImage,
    Features,
    Feed,
    FormValidation,
    Header,
    InitialSettings,
    Instance,
    LxNotificationService,
    ReduxStore,
    MainNav,
    Media,
    MediaConstant,
    MediaFactory,
    Metadata,
    Template,
    Translation,
    Upload,
    UploaderAction,
    User,
    UserAccess,
    Utils,
    Widget,
) {
    'ngInject';

    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const vm = this;

    // ///////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    // ///////////////////////////

    /**
     * The format for the date of a cycle.
     *
     * @type {string}
     */
    // eslint-disable-next-line no-underscore-dangle
    const _CYCLE_DATE_FORMAT = 'MMMM D, YYYY';

    /**
     * The lang currently in usage.
     *
     * @type {string}
     */
    // eslint-disable-next-line no-underscore-dangle
    const _currentLang = Translation.getLang('current');

    /**
     * List of date fields.
     *
     * @type {string[]}
     */
    // eslint-disable-next-line no-underscore-dangle
    const _dateFields = ['startDate', 'endDate', 'featuredStartDate', 'featuredEndDate'];

    /**
     * Indicates if a save is in progress.
     *
     * @type {boolean}
     */
    // eslint-disable-next-line no-underscore-dangle
    let _isSaving = false;

    /**
     * The view mode of the content depending on the template.
     * Used to set the view mode when content is set back to draft.
     *
     * @type {string}
     */
    // eslint-disable-next-line no-underscore-dangle
    let _newViewMode;

    // ///////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    // ///////////////////////////

    /**
     * Indicates if the use can change the archivers of the content.
     *
     * @type {boolean}
     */
    vm.canChangeArchivers = false;

    /**
     * Indicates if the use can change the deleters of the content.
     *
     * @type {boolean}
     */
    vm.canChangeDeleters = false;

    /**
     * Indicates if the user can change the publishers of the content.
     *
     * @type {boolean}
     */
    vm.canChangePublishers = false;

    /**
     * The custom content type of the current content we're creating / editing.
     *
     * @type {Object}
     */
    vm.currentCustomContentType = {};

    /**
     * Settings for the directory template dialog.
     *
     * @type {Object}
     */
    vm.directoryTemplateDialog = {
        isOpen: false,
        key: 'directory-template-settings',
    };

    /**
     * Indicates if we should display the helper text related to the mandatory end date of the content.
     *
     * @type {boolean}
     */
    vm.displayMandatoryEndDateHelper = false;

    /**
     * The identifier of the current content.
     * If there is no id in the current content, a new one is generated.
     *
     * @type {string}
     */
    vm.contentId = undefined;

    /**
     * Alias to the CONTENT_TYPES constant.
     *
     * @type {Array}
     */
    vm.contentTypes = InitialSettings.CONTENT_TYPES;

    /**
     * The identifier of the current custom content type.
     *
     * @type {string}
     */
    vm.customContentTypeId = undefined;

    /**
     * The maximum publication duration (number of days before automatic unpublishing).
     *
     * @type {number}
     */
    vm.endDateDelta = 0;

    /**
     * Indicates if the end date is mandatory.
     *
     * @type {boolean}
     */
    vm.endDateMandatory = false;

    /**
     * Indicates if the end date has been manually set or if it has been automatically computed from the settings.
     *
     * @type {boolean}
     */
    vm.endDateSetManually = false;

    /**
     * Indicates if we want to focus the title field of the content.
     *
     * @type {boolean}
     */
    vm.focusTitle = false;

    /**
     * Contains the sidebar content settings form.
     *
     * @type {Object}
     */
    vm.form = {};

    /**
     * Contains all the formatted dates.
     *
     * @type {Object}
     */
    vm.formattedDates = {};

    /**
     * List key of the notify feed selector
     */
    vm.notifyFeedListKey = 'notify-feeds';

    /**
     * Contains the list of feeds that shouldn't be displayed in the "Highlighted for" feed selector.
     * It can be empty, ALL, PUBLIC or both.
     *
     * @type {Array}
     */
    // eslint-disable-next-line no-undef
    vm.highlightedExceptions = angular.fastCopy(Feed.FEED_ALL_AND_PUBLIC);

    /**
     * Indicates if the end date has been computed from the delta or is a fixed date.
     *
     * @type {boolean}
     */
    vm.isDeltaDate = false;

    /**
     * Indicates if it's the first time this content is configured in its lifecycle.
     *
     * @type {boolean}
     */
    vm.lifeCycleFirstConfig = true;

    /**
     * The max date pickable as the end date.
     *
     * @type {Date}
     */
    vm.lxDatePickerMaxDate = undefined;

    /**
     * The min date pickable as the end date.
     *
     * @type {Date}
     */

    vm.lxDatePickerMinDate = undefined;

    /**
     * Contains all the navigation items.
     *
     * @type {Object}
     */
    vm.navigationItems = {};

    /**
     * Contains the feeds the user can select in the visibility and editors.
     *
     * @type {Array}
     */
    vm.restrictToFeeds = undefined;

    /**
     * The list key to list the tags when suggestions come back from the Machine Learning backend.
     *
     * @type {string}
     */
    vm.tagsListKey = 'tags-list';

    /**
     * The thumbnail to display.
     *
     * @type {boolean}
     */
    vm.featuredThumbnail = null;
    /**
     * Content from mediaThumbnail to crop and edit
     *
     * @type {Object}
     */
    vm.contextContent = null;

    /**
     * Cropped content from mediaThumbnail
     *
     * @type {Object}
     */
    vm.contextCroppedContent = null;

    /**
     * Should show modal cropper
     *
     * @type {boolean}
     */
    vm.cropperIsOpened = false;

    /**
     * Whether the notification alert is open.
     *
     * @type {boolean}
     */
    vm.notificationAlertIsOpen = false;

    /**
     * Array of feed objects to notify.
     *
     * @type {Array}
     */
    vm.feedsToNotify = [];

    /**
     * List key of the notify feed selector
     */
    vm.visibleByListKey = 'visible-by';

    /**
     * List key of the notify feed selector
     */
    vm.highlightedForListKey = 'highlighted-for';

    /**
     * Array of selected "visible by" groups ids.
     *
     * @type {Array}
     */
    vm.visibleByIds = [];

    /**
     * Array of selected highlighted groups ids.
     *
     * @type {Array}
     */
    vm.highlightedForIds = [];

    /**
     * Whether the visibleBy is empty after an attempt of saving
     */
    vm.isVisibleByMissing = false;

    /**
     * The options of the add featured image button
     */
    vm.imageUploadOptions = [
        { type: 'pick-from-media-library', onConfirm: FeaturedImage.onMediaPickerConfirm },
        { type: 'upload-from-computer', onConfirm: onUploadFromComputerConfirm },
    ];
    vm.isUploadImageDialogOpened = false;

    /*
     * Array of selected usale by groups ids in template permission
     */
    vm.usableByIds = [];
    // ///////////////////////////

    /**
     * Services and utilities.
     */
    vm.Community = Community;
    vm.CommunityTemplates = CommunityTemplates;
    vm.CommunityWizardConfiguration = CommunityWizardConfiguration;
    vm.Config = Config;
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.ContentForm = ContentForm;
    vm.CustomContentType = CustomContentType;
    vm.Directory = Directory;
    vm.Document = Document;
    vm.FeaturedImage = FeaturedImage;
    vm.Features = Features;
    vm.Feed = Feed;
    vm.FormValidation = FormValidation;
    vm.Instance = Instance;
    vm.MediaConstant = MediaConstant;
    vm.Metadata = Metadata;
    vm.Template = Template;
    vm.Translation = Translation;
    vm.User = User;
    vm.Utils = Utils;

    // ///////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    // ///////////////////////////

    // Functions related to the add featured image
    /**
     * @param {File} file
     */
    async function onUploadFromComputerConfirm(file) {
        await FeaturedImage.onUploadImageDialogFromComputerConfirm([file], updateContentThumbnail);
    }

    function updateContentThumbnail(responses, files) {
        FeaturedImage.addContentThumbnail([responses], vm.contentId, files);
        FeaturedImage.onUploadImageDialogClose();
    }

    /**
     * Indicates if a user can modify publishers, archivers and deleters only publishers can change publishers, and
     * so on but we need to desyncrone this to allow user to undo his own deletion...
     */
    // eslint-disable-next-line no-underscore-dangle
    function _canEditRights() {
        const currentContent = Content.getCurrent();

        const params = {
            checkContent: true,
            content: currentContent,
            customContentTypeId: currentContent.customContentType,
        };
        vm.canChangePublishers =
            // eslint-disable-next-line no-undef
            angular.isDefinedAndFilled(currentContent.customContentTypeDetails) &&
            currentContent.customContentTypeDetails.isWorkflowEnabled
                ? UserAccess.isUserAllowed('CUSTOM_CONTENT_PUBLISH', params)
                : false;
        vm.canChangeArchivers = UserAccess.isUserAllowed('CUSTOM_CONTENT_ARCHIVE', params);
        vm.canChangeDeleters = UserAccess.canDeleteContents([currentContent]);
    }

    /**
     * Check if template is correct.
     *
     * @return {boolean} Template is correct or not.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _checkTemplate() {
        const currentContent = Content.getCurrent();

        if (
            currentContent.template.basicMode &&
            // eslint-disable-next-line no-undef
            angular.isUndefinedOrEmpty(ContentTemplate.getElementList(currentContent, 'widget', 'widgetType', 'title'))
        ) {
            LxNotificationService.error(Translation.translate('WIDGET_TITLE_MANDATORY_BASIC_MODE'));

            return false;
        }

        if (
            // eslint-disable-next-line no-undef
            angular.isDefinedAndFilled(currentContent.template.name) &&
            Translation.hasTranslations(currentContent.template.name, true) &&
            !ContentForm.checkTemplateContentFeeds(vm.form.sidebarContentSettings)
        ) {
            return true;
        }

        LxNotificationService.error(Translation.translate('ERROR_MANDATORY_FIELDS'));

        return false;
    }

    /**
     * End the save.
     * Toggle the save in progress flag, hide the loader and display the error if any.
     *
     * @param {Object} [errorObject] The error to display (if any).
     */
    // eslint-disable-next-line no-underscore-dangle
    function _endSave(errorObject) {
        _isSaving = false;
        $rootScope.$broadcast('hide-loader');

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(errorObject)) {
            // Handle Nanny error.
            if (shouldUseNannyErrorHandler(ReduxStore.store.getState(), errorObject)) {
                ReduxStore.store.dispatch(handleNannyError(errorObject));
                return;
            }

            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(errorObject)) {
                Utils.displayServerError(errorObject);
            }
        }

        $scope.$emit('content-saved', errorObject);
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        _refreshFormattedDates();
    }

    /**
     * Format date in the right format.
     *
     * @param  {Object} date   The date to format.
     * @param  {string} format The format to transform the date into.
     * @param  {string} locale The locale/lang.
     * @return {Object} The formated date.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _formatDate(date, format, locale) {
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(date)) {
            return undefined;
        }

        // eslint-disable-next-line no-param-reassign
        format = format || _CYCLE_DATE_FORMAT;
        // eslint-disable-next-line no-param-reassign
        locale = locale || _currentLang;

        // eslint-disable-next-line no-undef
        return moment(date).locale(locale).format(format);
    }

    /**
     * Handle cycle of life, which is fancy words for mandatory endDate or endDate with delta.
     *
     * @param {Object} cct The cct with all the needed information.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _handleDataLifeCycle(cct) {
        const currentContent = Content.getCurrent();
        const cycleConfig = currentContent.properties.lastLifeCycle || {};

        vm.endDateMandatory =
            Config.AVAILABLE_CONTENT_TYPES.CUSTOM_LIST === currentContent.type ||
            Config.AVAILABLE_CONTENT_TYPES.DIRECTORY === currentContent.type
                ? false
                : Boolean(cct.isEndDateMandatory);
        vm.endDateDelta = cct.endDateDelta || 0;
        vm.isDeltaDate = Boolean(vm.endDateMandatory && vm.endDateDelta);
        vm.wasDeltaDate = Boolean(cycleConfig.delta && cycleConfig.mandatory);
        vm.endDateSetManually = cycleConfig.manual || false;

        let tmpDate;

        // If need to update end date.
        if (vm.isDeltaDate) {
            // eslint-disable-next-line no-undef
            tmpDate = _formatDate(moment().add(vm.endDateDelta, 'months'), _CYCLE_DATE_FORMAT);
            // eslint-disable-next-line no-undef
            vm.lxDatePickerMaxDate = moment(tmpDate, _CYCLE_DATE_FORMAT);
        }

        // If the new date is greater than the old one warn the user.
        if (
            // eslint-disable-next-line no-undef
            angular.isDefinedAndFilled(tmpDate) &&
            // eslint-disable-next-line no-undef
            moment(vm.formattedDates.endDate, _CYCLE_DATE_FORMAT) > moment(tmpDate, _CYCLE_DATE_FORMAT)
        ) {
            LxNotificationService.warning(Translation.translate('CONTENT_END_DATE_TOO_BIG'));
        }

        // Set new date.
        if (
            // eslint-disable-next-line no-undef
            angular.isDefinedAndFilled(tmpDate) &&
            (vm.lifeCycleFirstConfig || vm.endDateSetManually || cycleConfig.delta !== vm.endDateDelta) &&
            // eslint-disable-next-line no-undef
            angular.isNumber(vm.endDateDelta) &&
            angular.isUndefinedOrEmpty(currentContent.endDate)
        ) {
            if (!vm.endDateSetManually) {
                vm.formattedDates.endDate = tmpDate;
                // eslint-disable-next-line no-undef
                currentContent.endDateInput.date = moment(tmpDate, _CYCLE_DATE_FORMAT).startOf('day').format();
            }
        }
    }

    /**
     * Check if there anything wrong with the life cycle.
     *
     * @return {string} Return an error message.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _isLifeCycleValid() {
        const currentContent = Content.getCurrent();

        // Check if date is mandatory, and if so check if end date is set.
        if (vm.isMandatoryDateSet()) {
            return 'CONTENT_END_DATE_MANDATORY';
        }

        if (
            // eslint-disable-next-line no-undef
            angular.isUndefinedOrEmpty(currentContent.endDateInput) ||
            // eslint-disable-next-line no-undef
            angular.isUndefinedOrEmpty(currentContent.endDateInput.date)
        ) {
            return undefined;
        }

        // eslint-disable-next-line no-undef
        const inputDate = moment(currentContent.endDateInput.date);
        // eslint-disable-next-line no-undef
        const maxDate = angular.copy(vm.lxDatePickerMaxDate);

        // eslint-disable-next-line no-undef
        if (angular.isDefined(maxDate)) {
            // eslint-disable-next-line no-undef
            const now = moment();
            maxDate.hour(now.hour()).minute(now.minute());
        }

        // Set the hour and minute of the start date before checking it.
        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(currentContent.endDateInput.hour)) {
            inputDate.hour(currentContent.endDateInput.hour);
        } else {
            inputDate.hour(0);
        }

        // eslint-disable-next-line no-undef
        if (angular.isDefined(currentContent.endDateInput.min)) {
            inputDate.minute(currentContent.endDateInput.min);
        } else {
            inputDate.minute(0);
        }

        // eslint-disable-next-line no-undef
        if (inputDate < moment()) {
            return 'CONTENT_END_DATE_LOWER_THAN_TODAY';
        }

        // eslint-disable-next-line no-undef
        if (angular.isDefined(maxDate) && vm.isDeltaDate && inputDate.format() > moment(maxDate).format()) {
            return 'CONTENT_END_DATE_GREATER_THAN_ALLOWED';
        }

        return undefined;
    }

    /**
     * Refresh formatted start and end dates.
     *
     * @param {Object} [currentContent] The currect content object.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _refreshFormattedDates(currentContent) {
        // eslint-disable-next-line no-param-reassign
        currentContent = currentContent || Content.getCurrent();

        // Since content/save is served by monolite, all empty fiedls are returned as `null` value.
        // Avoid breaking the calendar by deleting date fields if the value is `null`.
        if (!currentContent.endDate) {
            delete currentContent.endDate;
        }
        if (!currentContent.startDate) {
            delete currentContent.startDate;
        }
        if (!currentContent.featuredEndDate) {
            delete currentContent.featuredEndDate;
        }
        if (!currentContent.featuredStartDate) {
            delete currentContent.featuredStartDate;
        }

        // We need a fallback for dateInput when we ask for a validate or when a content is refused.
        // eslint-disable-next-line no-restricted-syntax
        for (const dateField of _dateFields) {
            const dateInput = `${dateField}Input.date`;
            set(currentContent, dateInput, get(currentContent, dateInput) || currentContent[dateField]);
        }

        vm.formattedDates = {
            endDate: _formatDate(currentContent.endDateInput.date),
            featuredEndDate: _formatDate(currentContent.featuredEndDateInput.date),
            featuredStartDate: _formatDate(currentContent.featuredStartDateInput.date),
            startDate: _formatDate(currentContent.startDateInput.date),
        };
    }

    /**
     * Save header.
     *
     * @param {Function} [cb] Callback to execute after the save of the header.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _saveHeader(cb) {
        // eslint-disable-next-line no-undef, no-param-reassign
        cb = cb || angular.noop;

        const currentContent = Content.getCurrent();
        const currentHeader = Header.getCurrent();

        if (
            currentHeader.name === 'default' ||
            // eslint-disable-next-line no-undef
            (angular.isDefinedAndFilled(Instance.getInstance().defaultHeader) &&
                currentHeader.uid === Instance.getInstance().defaultHeader)
        ) {
            if (currentHeader.id !== currentContent.header) {
                currentContent.header = currentHeader.id;
            }

            cb();
        } else {
            const identifier = currentHeader.uid || currentHeader.id;

            /**
             * Make sure the content header id is not the same as the template one otherwise we will overwrite the
             * template header for all the content that point to it.
             */
            // eslint-disable-next-line no-undef
            if (angular.isDefinedAndFilled(identifier) && get(currentContent.template, 'header') === identifier) {
                currentHeader.id = undefined;
                currentHeader.uid = undefined;
            }

            currentHeader.media = map(currentHeader.properties.media, 'id');

            Header.save(
                currentHeader,
                (response) => {
                    currentContent.header = response.id;

                    cb();
                },
                _endSave,
            );
        }
    }

    /**
     * Save each unassigned media during the create mode.
     *
     * @param {Function} cb Callback to execute after media management.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _saveUnassignedMedia(cb) {
        const unassignedMediaList = Media.getUnassignedMedia();

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(unassignedMediaList)) {
            // eslint-disable-next-line no-undef
            angular.forEach(unassignedMediaList, (media) => {
                // eslint-disable-next-line no-param-reassign
                media.status = Config.CONTENT_STATUS.LIVE.value;
                // eslint-disable-next-line no-param-reassign
                media.contentKey = Content.getCurrent().id;
                // eslint-disable-next-line no-param-reassign
                media.properties.content = Content.getCurrent().id;
            });

            MediaFactory.saveMulti(
                {
                    items: unassignedMediaList,
                },
                cb,
                _endSave,
            );
        } else {
            cb();
        }
    }

    /**
     * Save content.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _save() {
        const currentContent = Content.getCurrent();

        currentContent.properties.lastLifeCycle = {
            delta: vm.endDateDelta,
            endDate: currentContent.endDate,
            mandatory: vm.endDateMandatory,
            manual: vm.endDateSetManually,
        };

        currentContent.feedKeys = vm.visibleByIds;
        currentContent.featuredFeedKeys = vm.highlightedForIds;

        ContentForm.saveContent((content) => {
            _saveUnassignedMedia(() => {
                _endSave();

                const actualStatus = Content.originalContent.status;

                Content.setCurrent(content);
                // eslint-disable-next-line no-undef, no-param-reassign
                Content.originalContent = angular.fastCopy(content);

                const hasDistributeContentFF = Features.hasFeature('broadcast-distribute-content');
                const canUserAccessBroadcast = canAccessBroadcast(ReduxStore.store.getState());
                const isLayoutEnabled = isLayoutEnabledSelector(ReduxStore.store.getState());

                const contentRoute = contentView({
                    to: {
                        id: content.id,
                        slug: Translation.translate(content.slug),
                        query: { distribute: 'true' },
                        isV2Compatible: content.template.isV2Compatible,
                    },
                    isLayoutEnabled,
                });
                if (
                    hasDistributeContentFF &&
                    canUserAccessBroadcast &&
                    content.status === Config.CONTENT_STATUS.LIVE.value &&
                    actualStatus !== Config.CONTENT_STATUS.LIVE.value &&
                    (content.type === InitialSettings.CONTENT_TYPES.CUSTOM ||
                        content.type === InitialSettings.CONTENT_TYPES.NEWS ||
                        content.type === InitialSettings.CONTENT_TYPES.PAGE)
                ) {
                    ReduxStore.store.dispatch(
                        showContentPublishedDistributeNotfication(
                            Translation.translate(CONTENTS.CONTENT_PUBLISHED),
                            contentRoute,
                        ),
                    );
                } else {
                    LxNotificationService.success(Translation.translate('CONTENT_SAVE_SUCCESS'));
                }

                // If the content is a directory, update the local list.
                if (Content.getCurrent().type === InitialSettings.CONTENT_TYPES.DIRECTORY) {
                    Directory.updateLocalList(Directory.getCurrent(), Content.getCurrent());
                }

                // Only update the MainNav once a content is actually published and live.
                if (currentContent.status === Config.CONTENT_STATUS.LIVE.value) {
                    // Reset pending element.
                    MainNav.resetElements();

                    MainNav.init();

                    vm.navigationItems = {};
                }

                if ($state.current.name === 'app.front.content-edit' && $state.params.key === content.id) {
                    // eslint-disable-next-line no-undef
                    if (angular.isDefinedAndFilled(_newViewMode)) {
                        Content.setViewMode(_newViewMode);

                        _newViewMode = undefined;
                    }
                } else {
                    $state.go('app.front.content-edit', {
                        key: content.id,
                    });
                }

                _canEditRights();
            });
        }, _endSave);
    }

    /**
     * Get the cropped thumbnail if exists in the good language.
     * If not, get the original thumbnail.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _getFeaturedImageURL() {
        const rootContent = vm.Content.getCurrent();
        return Document.getCroppedThumbnailFromRootContent(rootContent, true, 610, Translation.inputLanguage);
    }

    /**
     * Get the cropped content if exists.
     */
    function getContentCrop(type) {
        const currentContent = vm.Content.getCurrent();
        if (currentContent.mediaThumbnail) {
            const content = Document.getMediaContentByLang(
                currentContent.mediaThumbnail,
                true,
                Translation.inputLanguage,
                false,
                type,
            );
            return content;
        }
        return undefined;
    }

    /**
     * Upload thumbnail src, crop information and focal point information
     */
    // eslint-disable-next-line no-underscore-dangle
    function _refreshThumbnail() {
        $timeout(() => {
            vm.featuredThumbnail = _getFeaturedImageURL();
            vm.featuredThumbnailBlob = getImageForThumbnail(vm.featuredThumbnail);
            vm.contextContent = vm.getContentCrop('content');
            vm.isCropDisabled = !vm.contextContent || !vm.contextContent.servingUrl;
            vm.contextCroppedContent = vm.getContentCrop();
        });
    }

    /**
     * Upload cropped image and return media.
     *
     * @param {Object} canvas The `Cropper` canvas.
     */
    // eslint-disable-next-line no-underscore-dangle
    function uploadCropContent(canvas) {
        return new Promise((resolve) => {
            canvas.toBlob((blob) => {
                Upload.uploadMultiple([blob], undefined, (response) => {
                    resolve({
                        ...response,
                        name: (vm.contextContent && vm.contextContent.name) || response.name,
                    });
                });
            });
        });
    }

    async function updateCurrentContent(focalPointInfo, cropInfo, canvasCroppedImage) {
        const currentContent = Content.getCurrent();
        // eslint-disable-next-line no-undef
        const copySelectedMedia = angular.fastCopy(currentContent.mediaThumbnail);
        const lang = Translation.inputLanguage;

        if (canvasCroppedImage) {
            const mediaCropped = await uploadCropContent(canvasCroppedImage);
            copySelectedMedia.croppedContent = [];
            Document.removeCrop(copySelectedMedia, lang);
            const formatedMedia = Media.formatUploadedFile(mediaCropped, lang);
            const firstContent = (formatedMedia.content && formatedMedia.content[0]) || {};
            firstContent.focalPoint = focalPointInfo;
            firstContent.cropInfo = cropInfo;
            copySelectedMedia.croppedContent.push(firstContent);
            copySelectedMedia.hasCroppedContent = true;
        } else {
            Document.removeCrop(copySelectedMedia, lang);
        }

        copySelectedMedia.content = copySelectedMedia.content || [{}];
        copySelectedMedia.content[0].focalPoint = focalPointInfo;
        copySelectedMedia.content[0].cropInfo = cropInfo;

        const newContentCurrent = {
            ...currentContent,
            mediaThumbnail: copySelectedMedia,
        };
        return newContentCurrent;
    }

    // ///////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    // ///////////////////////////

    /**
     * Add a tag to the list of selected tag of the current content.
     *
     * @param {Object} tag The tag to select.
     */
    function addTag(tag) {
        const currentContent = Content.getCurrent();
        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(currentContent)) {
            return;
        }

        currentContent.customContentTypeTags = currentContent.customContentTypeTags || [];

        if (!includes(currentContent.customContentTypeTags, tag.uuid)) {
            currentContent.customContentTypeTags.push(tag.uuid);
        }
    }

    /**
     * Check if user can notify users when saving the current content.
     *
     * @return {boolean} The user can notify when saving the current content or not.
     */
    function canNotifyUsers() {
        const currentContent = Content.getCurrent();

        return (
            // eslint-disable-next-line no-undef
            angular.isUndefinedOrEmpty(currentContent.customContentTypeDetails) ||
            !currentContent.customContentTypeDetails.isWorkflowEnabled ||
            (currentContent.status === Config.CONTENT_STATUS.LIVE.value && UserAccess.canManageInstanceSettings())
        );
    }

    /**
     * Delete content or template.
     */
    function deleteContent() {
        LxNotificationService.confirm(
            Translation.translate('CONTENT_DELETE'),
            Translation.translate('CONTENT_DELETE_DESCRIPTION'),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            (answer) => {
                if (answer) {
                    const currentContent = Content.getCurrent();

                    if (currentContent.type === 'template') {
                        Template.del(
                            currentContent.template.id,
                            () => {
                                LxNotificationService.success(Translation.translate('TEMPLATE_DELETE_SUCCESS'));

                                angularApi.redirect(adminContentTemplates(currentContent.template.customContentType));
                            },
                            (err) => {
                                Utils.displayServerError(err);
                            },
                        );
                    } else {
                        Content.del(
                            currentContent.id,
                            () => {
                                LxNotificationService.success(Translation.translate('CONTENT_DELETE_SUCCESS'));

                                $state.go('app.admin.content', {
                                    uid: currentContent.customContentType,
                                });
                            },
                            (err) => {
                                Utils.displayServerError(err);
                            },
                        );
                    }
                }
            },
        );
    }

    /**
     * Used by LumX lx-select "modelToSelection", transform data value to fit with ngModel wanted.
     *
     * @param {Object}   data Data given by the lumx select.
     * @param {Function} cb   Execute callback if the element is found.
     */
    function emailToUserObject(data, cb) {
        const currentContent = Content.getCurrent();

        if (
            // eslint-disable-next-line no-undef
            angular.isDefinedAndFilled(data) &&
            // eslint-disable-next-line no-undef
            angular.isDefined(currentContent.writerDetails) &&
            // eslint-disable-next-line no-undef
            angular.isDefinedAndFilled(currentContent.writerDetails.email) &&
            currentContent.writerDetails.email === data
        ) {
            cb(currentContent.writerDetails);
        }
    }

    /**
     * Callback of the end date selection.
     */
    function endDatePickerCallback() {
        vm.endDateSetManually = true;
    }

    /**
     * Callback of the end date selection.
     */
    // eslint-disable-next-line no-underscore-dangle
    function _endDateUpdated() {
        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(vm.formattedDates.endDate)) {
            vm.displayMandatoryEndDateHelper = false;

            return;
        }

        // The end date has been manually cleared but we want to force an end date anyway since its mandatory.
        if (vm.endDateMandatory) {
            vm.endDateSetManually = false;
            vm.displayMandatoryEndDateHelper = true;

            // Reset the end date to whatever the default mandatory of the CCT is.
            const cycleConfig = Content.getCurrent().properties.lastLifeCycle || {};
            vm.lifeCycleFirstConfig = true;
            cycleConfig.manual = false;

            _handleDataLifeCycle(vm.currentCustomContentType);
        }
    }

    /**
     * Is Mandatory date set.
     *
     * @return {boolean} If the endDate is mandatory or not.
     */
    function isMandatoryDateSet() {
        return (
            vm.endDateMandatory &&
            // eslint-disable-next-line no-undef
            (angular.isUndefinedOrEmpty(Content.getCurrent().endDateInput) || !vm.formattedDates.endDate)
        );
    }

    /**
     * Open Directory Template Settings Modal.
     */
    function openDirectoryTemplateSettings() {
        vm.directoryTemplateDialog.isOpen = true;
        Utils.waitForAndExecute(`#${vm.directoryTemplateDialog.key}`);
    }

    /**
     * Update usable by values for template permissions
     */
    function onUsableByChange(usableByGroups) {
        Content.getCurrent().template.usableBy = usableByGroups.map((g) => g.id);
        vm.usableByIds = Content.getCurrent().template.usableBy;
    }

    /**
     * Save content and header.
     *
     * @param {boolean} asDraft Indicates if we want to save the content as draft, or save it and publish it.
     */
    function saveContent(asDraft) {
        const currentContent = Content.getCurrent();
        let lifeCycleError;

        currentContent.feedKeys = vm.visibleByIds;
        currentContent.featuredFeedKeys = vm.highlightedForIds;

        if (currentContent.type === 'template') {
            if (_checkTemplate()) {
                $scope.$emit('show-loader');

                // Apply content type string to fix unexpected values due to copies
                currentContent.template.contentType = CustomContentType.getCurrent().contentType;

                _saveHeader(() => {
                    if (currentContent.header) {
                        // eslint-disable-next-line no-undef
                        currentContent.template.header = angular.copy(currentContent.header);
                    }

                    currentContent.template.customContentType =
                        currentContent.customContentType || Template.getCurrent().customContentType;

                    if (currentContent.template.heritable === false) {
                        currentContent.template.visibilityInheritedOnly = false;
                    } else if (angular.isDefinedAndFilled(get(Instance.getInstance(), 'parent'))) {
                        // If the template is created from a content which is created from an inherited template with theses options we need to reset them.
                        currentContent.template.heritable = false;
                        currentContent.template.visibilityInheritedOnly = false;
                    }

                    Template.save(
                        currentContent.template,
                        (template) => {
                            _saveUnassignedMedia(() => {
                                _endSave();

                                currentContent.template = template;
                                // eslint-disable-next-line no-undef, no-param-reassign
                                Content.originalContent = angular.copy(currentContent);

                                LxNotificationService.success(Translation.translate('TEMPLATE_SAVE_SUCCESS'));

                                $state.go('app.front.content-edit', {
                                    isTemplate: true,
                                    key: template.id,
                                });
                                _canEditRights();
                            });
                        },
                        _endSave,
                    );
                });

                return;
            }

            if (vm.form.sidebarContentSettings) {
                FormValidation.setFormDirty(vm.form.sidebarContentSettings);
            }

            _isSaving = false;
        } else if (currentContent.type === InitialSettings.CONTENT_TYPES.DIRECTORY) {
            lifeCycleError = _isLifeCycleValid();

            if (lifeCycleError) {
                LxNotificationService.error(Translation.translate(lifeCycleError));

                _isSaving = false;

                return;
            }

            if (Content.getViewMode() === 'basic' && Content.getAction() === 'create') {
                // eslint-disable-next-line no-undef
                angular.forEach(ContentForm.getTmpSlug(), (value, lang) => {
                    // eslint-disable-next-line no-undef
                    if (angular.isDefinedAndFilled(value)) {
                        // eslint-disable-next-line no-param-reassign
                        ContentForm.getTmpSlug()[lang] += `-${generateUUID()}`;
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        ContentForm.getTmpSlug()[lang] = generateUUID();
                    }
                });

                // eslint-disable-next-line no-undef
                if (angular.isDefinedAndFilled(currentContent.template.feedKeys)) {
                    currentContent.feedKeys = currentContent.template.feedKeys;
                }

                // eslint-disable-next-line no-undef
                if (angular.isDefinedAndFilled(currentContent.template.featuredFeedKeys)) {
                    currentContent.featuredFeedKeys = currentContent.template.featuredFeedKeys;
                }
            }

            $timeout(async () => {
                if (ContentForm.checkContent()) {
                    $scope.$emit('show-loader');

                    const template = currentContent.template || {};

                    const [contributionWidget] = Widget.findWidgetsByType(
                        template,
                        InitialSettings.WIDGET_TYPES.CONTRIBUTION,
                    );

                    // If there is a contribution widget and it has dita or richText in it, then we need to save a structured content
                    if (
                        contributionWidget &&
                        (angular.isDefinedAndFilled(contributionWidget.properties.dita) ||
                            angular.isDefinedAndFilled(contributionWidget.properties.richText))
                    ) {
                        try {
                            const titleTranslations = Instance.getInstance().langs.reduce((acc, lang) => {
                                return {
                                    ...acc,
                                    [lang]: Translation.translate(currentContent.title, '', lang, true, false, true),
                                };
                            }, {});
                            await saveStructuredContentFromWidget(contributionWidget, titleTranslations);
                        } catch (error) {
                            _endSave(error);

                            return;
                        }
                    }

                    // eslint-disable-next-line no-param-reassign
                    Directory.getCurrent().name = currentContent.title;
                    // eslint-disable-next-line no-param-reassign
                    Directory.getCurrent().properties = currentContent.properties;

                    Directory.save(
                        Directory.getCurrent(),
                        (response) => {
                            currentContent.externalKey = response.id;
                            currentContent.status = Config.CONTENT_STATUS.LIVE.value;
                            _saveHeader(_save);
                        },
                        _endSave,
                    );

                    return;
                }

                // eslint-disable-next-line no-undef
                if (angular.isDefinedAndFilled(vm.form.sidebarContentSettings)) {
                    FormValidation.setFormDirty(vm.form.sidebarContentSettings);
                }

                vm.isVisibleByMissing = vm.visibleByIds?.length === 0;
                _isSaving = false;
            });
        } else if (currentContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
            $timeout(async () => {
                if (
                    ($stateParams.type === 'template' || $stateParams.isTemplate) &&
                    CommunityTemplates.checkCommunityTemplate()
                ) {
                    $scope.$emit('show-loader');

                    _saveHeader(() => {
                        const currentCommunity = Content.getCurrent();
                        CommunityTemplate.updateTemplate(currentCommunity);

                        // eslint-disable-next-line no-undef
                        const communityTemplateToSave = angular.fastCopy(CommunityTemplates.getCurrent());
                        // eslint-disable-next-line no-undef
                        communityTemplateToSave.templates = angular.fastCopy(currentCommunity.templates);

                        CommunityTemplates.save(
                            communityTemplateToSave,
                            (response) => {
                                // Update community template.
                                CommunityTemplate.setAsCurrent(response, CommunityTemplate.orig);

                                LxNotificationService.success(
                                    Translation.translate('FRONT.COMMUNITY_TEMPLATES.SAVE_SUCCESS'),
                                );

                                if (Content.getAction() === 'create') {
                                    $state.go('app.front.content-edit', {
                                        contentType: 'community',
                                        isTemplate: true,
                                        key: response.uid,
                                        subTemplate: $stateParams.template || 'posts',
                                    });
                                }

                                _endSave();
                            },
                            _endSave,
                        );
                    });
                } else if ($stateParams.type !== 'template' && ContentForm.checkContent(false, false)) {
                    $scope.$emit('show-loader');

                    const [contributionWidget] = Widget.findWidgetsByType(
                        currentContent.template,
                        InitialSettings.WIDGET_TYPES.CONTRIBUTION,
                    );

                    // If there is a contribution widget and it has dita or rich text in it, then we need to save a structured content
                    if (
                        contributionWidget &&
                        (angular.isDefinedAndFilled(contributionWidget.properties.dita) ||
                            angular.isDefinedAndFilled(contributionWidget.properties.richText))
                    ) {
                        try {
                            const titleTranslations = Instance.getInstance().langs.reduce((acc, lang) => {
                                return {
                                    ...acc,
                                    [lang]: Translation.translate(currentContent.title, '', lang, true, false, true),
                                };
                            }, {});
                            await saveStructuredContentFromWidget(contributionWidget, titleTranslations);
                        } catch (error) {
                            _endSave(error);

                            return;
                        }
                    }

                    _saveHeader(() => {
                        // Swap back to origin (before save).
                        // Copy so user don't see the change in the designer !!
                        // eslint-disable-next-line no-undef
                        const community = angular.copy(Content.getCurrent());

                        CommunityTemplate.swapBack(community);

                        Community.saveLayout(
                            community,
                            (response) => {
                                // Update Content.
                                CommunityTemplate.setAsCurrent(response, get(CommunityTemplate, 'orig'));
                                Content.setCurrent(response);

                                // Update original content to avoid leaving confirmation modal to pop inopportunely.
                                // Only relevant when community template haven't been saved yet.
                                // eslint-disable-next-line no-undef, no-param-reassign
                                Content.originalContent = angular.fastCopy(Content.getCurrent());

                                LxNotificationService.success(Translation.translate('CONTENT_SAVE_SUCCESS'));
                                _endSave();
                            },
                            _endSave,
                        );
                    });
                }
            });
        } else {
            lifeCycleError = _isLifeCycleValid();

            if (lifeCycleError) {
                LxNotificationService.error(Translation.translate(lifeCycleError));

                _isSaving = false;

                return;
            }

            // Force end date update on save if not set manually.
            // eslint-disable-next-line no-underscore-dangle
            const _cct = currentContent.customContentTypeDetails;
            vm.endDateMandatory =
                Config.AVAILABLE_CONTENT_TYPES.CUSTOM_LIST === currentContent.type
                    ? false
                    : Boolean(_cct.isEndDateMandatory);
            vm.endDateDelta = _cct.endDateDelta || 0;
            vm.isDeltaDate = Boolean(vm.endDateMandatory && vm.endDateDelta);

            if (!vm.endDateSetManually && vm.isDeltaDate && angular.isUndefinedOrEmpty(currentContent.endDate)) {
                // eslint-disable-next-line no-undef
                vm.formattedDates.endDate = _formatDate(moment().add(vm.endDateDelta, 'months'), _CYCLE_DATE_FORMAT);

                if (vm.formattedDates.endDate) {
                    // eslint-disable-next-line no-undef
                    vm.lxDatePickerMaxDate = moment(vm.formattedDates.endDate, _CYCLE_DATE_FORMAT);
                    // eslint-disable-next-line no-undef
                    currentContent.endDateInput.date = moment(vm.formattedDates.endDate, _CYCLE_DATE_FORMAT)
                        .startOf('day')
                        .format();
                }
            }

            if (Content.getViewMode() === 'basic' && Content.getAction() === 'create') {
                // eslint-disable-next-line no-undef
                angular.forEach(ContentForm.getTmpSlug(), (value, lang) => {
                    // eslint-disable-next-line no-undef
                    if (angular.isDefinedAndFilled(value)) {
                        // eslint-disable-next-line no-param-reassign
                        ContentForm.getTmpSlug()[lang] += `-${generateUUID()}`;
                    } else {
                        // eslint-disable-next-line no-param-reassign
                        ContentForm.getTmpSlug()[lang] = generateUUID();
                    }
                });

                // eslint-disable-next-line no-undef
                if (angular.isDefinedAndFilled(currentContent.template.feedKeys)) {
                    currentContent.feedKeys = currentContent.template.feedKeys;
                }

                // eslint-disable-next-line no-undef
                if (angular.isDefinedAndFilled(currentContent.template.featuredFeedKeys)) {
                    currentContent.featuredFeedKeys = currentContent.template.featuredFeedKeys;
                }
            }

            $timeout(async () => {
                if (ContentForm.checkContent()) {
                    $scope.$emit('show-loader');

                    const template = currentContent.template || {};

                    const [contributionWidget] = Widget.findWidgetsByType(
                        template,
                        InitialSettings.WIDGET_TYPES.CONTRIBUTION,
                    );

                    // If there is a contribution widget and it has dita or richText in it, then we need to save a structured content
                    if (
                        contributionWidget &&
                        (angular.isDefinedAndFilled(contributionWidget.properties.dita) ||
                            angular.isDefinedAndFilled(contributionWidget.properties.richText))
                    ) {
                        try {
                            const titleTranslations = Instance.getInstance().langs.reduce((acc, lang) => {
                                return {
                                    ...acc,
                                    [lang]: Translation.translate(currentContent.title, '', lang, true, false, true),
                                };
                            }, {});
                            await saveStructuredContentFromWidget(contributionWidget, titleTranslations);
                        } catch (error) {
                            _endSave(error);

                            return;
                        }
                    }

                    if (asDraft) {
                        currentContent.status = asDraft
                            ? Config.CONTENT_STATUS.DRAFT.value
                            : Config.CONTENT_STATUS.LIVE.value;

                        const action = Content.getAction();

                        const isDraft = currentContent.status === Config.CONTENT_STATUS.DRAFT.value;
                        if (!Content.isEditable(isDraft, true) || action === 'style') {
                            _newViewMode = 'locked';
                        } else if (template.fixedLayout && template.fixedWidgets) {
                            _newViewMode = template.basicMode ? 'basic' : 'simple';
                        } else {
                            _newViewMode = 'default';
                        }
                    } else {
                        currentContent.status = Content.isWorkflowEnabled(currentContent)
                            ? currentContent.status
                            : Config.CONTENT_STATUS.LIVE.value;
                    }

                    _saveHeader(_save);

                    return;
                }
                if (vm.form.sidebarContentSettings) {
                    FormValidation.setFormDirty(vm.form.sidebarContentSettings);
                }

                vm.isVisibleByMissing = vm.visibleByIds?.length === 0;
                _isSaving = false;
            });
        }
    }

    /**
     * Disable basic mode and/or fixed widgets options when fixed layout or fixed widgets options are disabled.
     */
    function updateFixedLayout() {
        const content = Content.getCurrent();

        if (!content.template.fixedLayout) {
            content.template.fixedWidgets = false;
        }

        if (!content.template.fixedWidgets) {
            content.template.basicMode = false;
        }
    }

    /**
     * Update the writer details.
     */
    function updateWriterDetails() {
        const currentContent = Content.getCurrent();

        // eslint-disable-next-line no-undef
        if (angular.isUndefinedOrEmpty(Content.getCurrent().writer)) {
            currentContent.writerId = '';
            currentContent.writerDetails = undefined;

            return;
        }

        const writersList = User.displayList('writer');
        for (let i = 0, len = writersList.length; i < len; i++) {
            if (writersList[i].email === Content.getCurrent().writer) {
                currentContent.writerDetails = writersList[i];

                return;
            }
        }
    }

    /**
     * Used by LumX lx-select "modelToSelection", transform data value to fit with ngModel wanted.
     *
     * @param {Object}   data Data given by the lumx select.
     * @param {Function} cb   Execute callback if the element is found.
     */
    function userObjectToEmail(data, cb) {
        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(data.email)) {
            cb(data.email);
        }
    }

    /**
     * On input date field changed.
     * Used to reset the hour/min when the corresponding date is cleared.
     *
     * @param {string} dateInputField The date input field name.
     */
    function onDateInputChange(dateInputField) {
        const dateInputValue = Content.getCurrent()[`${dateInputField}Input`];
        if (dateInputValue && (!vm.formattedDates[dateInputField] || !dateInputValue.date)) {
            delete dateInputValue.date;
            delete dateInputValue.hour;
            delete dateInputValue.min;
        }

        if (dateInputField === 'endDate') {
            _endDateUpdated();
        }
    }

    /**
     * Closes the cropper.
     */
    function onCropperClose() {
        $timeout(() => {
            vm.cropperIsOpened = false;
        }, 1000);
    }

    /**
     * Opens the alert dialog when feeds to notify are selected.
     */
    function openNotificationAlert() {
        vm.notificationAlertIsOpen = true;
    }

    /**
     * Close the alert dialog when feeds to notify are selected.
     */
    function closeNotificationAlert() {
        $timeout(() => {
            vm.notificationAlertIsOpen = false;
        }, 0);
    }

    /**
     * Opens the cropper dialog.
     */
    function openCropper() {
        vm.cropperIsOpened = true;
    }

    /**
     * Saves crop and focal point info and attach them to
     * the original media.content and media.croppedContent if exists.
     *
     * @param {Object} focalPointInfo Information about the Focal Point.
     * @param {Object} cropInfo Infomation about the crop.
     * @param {Object} canvas The `Cropper` canvas (optionnal, if image has been cropped)
     */
    async function onCropperConfirm(focalPointInfo, cropInfo, canvasCroppedImage) {
        const newContentCurrent = await updateCurrentContent(focalPointInfo, cropInfo, canvasCroppedImage);
        Content.setCurrent(newContentCurrent);
        _refreshThumbnail();
        $rootScope.$broadcast('update-featured-image-thumbnail');
        onCropperClose();
    }

    /**
     * Trigger the content save.
     */
    function submitSaveContent(asDraft) {
        if (!_isSaving) {
            _isSaving = true;

            vm.saveContent(asDraft);
        }
    }

    /**
     * Function called when the notification alert dialog is confirmed.
     *
     * This will set the notifyUsers to true;
     */
    function onAlertNotificationConfirm() {
        closeNotificationAlert();
        submitSaveContent();
    }

    function editFeaturedImage() {
        if (Content.getAction() !== 'get' && Content.getViewMode() === 'basic') {
            UploaderAction.openSelection(vm.contentId);
        }
    }

    /**
     * Get upload url request parameters.
     *
     * @return {Object} Request parameters.
     */
    async function getUploadUrlRequestParameters() {
        const currentSite = Instance.getCurrentInstanceId();

        const parentPath = 'provider=local/site=' + currentSite;

        return {
            parentPath,
            shared: false,
        };
    }

    function onVisibleGroupTargetingChange(visibleByGroups) {
        vm.isVisibleByMissing = false;
        vm.visibleByIds = visibleByGroups.map((g) => g.id);

        vm.Content.getCurrent().feedKeys = vm.visibleByIds;

        // compute exceptions used by "Groups to notify"
        const isTemplate = Content.getCurrent().type === 'template';
        const canPickAll = includes(vm.visibleByIds, Feed.ALL.id) || isTemplate;

        /**
         * Should never be able to pick PUBLIC
         * If ALL is selected in the visible by, then I can pick it
         */
        vm.highlightedExceptions = !canPickAll ? angular.fastCopy(Feed.FEED_ALL_AND_PUBLIC) : [Feed.PUBLIC.id];
    }

    function onHighlightedGroupTargetingChange(highlightedForGroups) {
        vm.highlightedForIds = highlightedForGroups.map((g) => g.id);

        vm.Content.getCurrent().featuredFeedKeys = vm.highlightedForIds;
    }

    function onTemplateVisibleGroupTargetingChange(visibleByGroups) {
        vm.isVisibleByMissing = false;
        vm.visibleByIds = visibleByGroups.map((g) => g.id);

        vm.Content.getCurrent().template.feedKeys = vm.visibleByIds;

        // compute exceptions used by "Groups to notify"
        const isTemplate = Content.getCurrent().type === 'template';
        const canPickAll = includes(vm.visibleByIds, Feed.ALL.id) || isTemplate;

        /**
         * Should never be able to pick PUBLIC
         * If ALL is selected in the visible by, then I can pick it
         */
        vm.highlightedExceptions = !canPickAll ? angular.fastCopy(Feed.FEED_ALL_AND_PUBLIC) : [Feed.PUBLIC.id];
    }

    function onTemplateHighlightedGroupTargetingChange(highlightedForGroups) {
        vm.highlightedForIds = highlightedForGroups.map((g) => g.id);

        vm.Content.getCurrent().template.featuredFeedKeys = vm.highlightedForIds;
    }

    // Functions related to the upload image dialog
    function onUploadImageDialogClose() {
        vm.isUploadImageDialogOpened = false;
    }

    function onUploadImageDialogOpen() {
        vm.isUploadImageDialogOpened = true;
    }

    // ///////////////////////////

    vm.addTag = addTag;
    vm.canNotifyUsers = canNotifyUsers;
    vm.contains = includes;
    vm.deleteContent = deleteContent;
    vm.emailToUserObject = emailToUserObject;
    vm.endDatePickerCallback = endDatePickerCallback;
    vm.getContentCrop = getContentCrop;
    vm.isMandatoryDateSet = isMandatoryDateSet;
    vm.onDateInputChange = onDateInputChange;
    vm.onCropperClose = onCropperClose;
    vm.onCropperConfirm = onCropperConfirm;
    vm.openCropper = openCropper;
    vm.openDirectoryTemplateSettings = openDirectoryTemplateSettings;
    vm.saveContent = saveContent;
    vm.updateFixedLayout = updateFixedLayout;
    vm.updateWriterDetails = updateWriterDetails;
    vm.userObjectToEmail = userObjectToEmail;
    vm.openNotificationAlert = openNotificationAlert;
    vm.closeNotificationAlert = closeNotificationAlert;
    vm.onAlertNotificationConfirm = onAlertNotificationConfirm;
    vm.editFeaturedImage = editFeaturedImage;
    vm.getUploadUrlRequestParameters = getUploadUrlRequestParameters;
    vm.onVisibleGroupTargetingChange = onVisibleGroupTargetingChange;
    vm.onHighlightedGroupTargetingChange = onHighlightedGroupTargetingChange;
    vm.onTemplateVisibleGroupTargetingChange = onTemplateVisibleGroupTargetingChange;
    vm.onTemplateHighlightedGroupTargetingChange = onTemplateHighlightedGroupTargetingChange;
    vm.onUploadImageDialogClose = onUploadImageDialogClose;
    vm.onUploadImageDialogOpen = onUploadImageDialogOpen;
    vm.onUsableByChange = onUsableByChange;

    // ///////////////////////////
    //                         //
    //          Events         //
    //                         //
    // ///////////////////////////

    /**
     * Content status is updated.
     * Generate formatted dates if status is "to validate".
     *
     * @param {Event}  evt           The event triggering this method.
     * @param {string} contentStatus The content status.
     */
    $scope.$on('content-status-updated', (evt, contentStatus) => {
        if (contentStatus !== Config.CONTENT_STATUS.TO_VALIDATE.value) {
            return;
        }

        _refreshFormattedDates();
    });

    /**
     * Delete content is broadcasted, do the deletion.
     */
    $scope.$on('delete-content', vm.deleteContent);

    /**
     * Input language is changed.
     * Set the form to its pristine state.
     */
    $scope.$on('inputLanguage', () => {
        // eslint-disable-next-line no-undef
        if (angular.isDefined(vm.form.sidebarContentSettings)) {
            vm.form.sidebarContentSettings.$setPristine();
        }
    });

    /**
     * Reset the status of the directory template dialog when it's being closed.
     *
     * @param {Event}  evt      The event triggering this method
     * @param {string} dialogId The identifier of the dialog that triggered this function.
     */
    $scope.$on('lx-dialog__close-end', (evt, dialogId) => {
        if (dialogId === vm.directoryTemplateDialog.key) {
            vm.directoryTemplateDialog.isOpen = false;
        }
    });

    /**
     * Build an array of feeds from an array of feed keys
     * @param {Array} feedKeys The keys of the feeds to format.
     * @returns {Array} The array of formatted feeds.
     */
    function feedKeyToFeed(feedKeys = [], id = undefined) {
        // Create array of feed objects
        const tmpFeedsToNotify = [];
        feedKeys.forEach((key) => {
            Feed.feedKeyToFeed(
                key,
                // Fill the array in callback
                (feed) => {
                    tmpFeedsToNotify.push(feed);
                },
                id ? id : vm.notificationGroupFeedKey,
            );
        });

        return tmpFeedsToNotify;
    }

    /**
     * Watches changes on the notify feed field.
     * When it goes from empty to at least one feed, set "notifyAuthor" to true.
     */

    $scope.$watch(
        'vm.Content.getCurrent().notifyFeedKeys',
        function onNotifyFeedChange(newValue = [], oldValue) {
            if (
                newValue?.length > 0 &&
                oldValue?.length === 0 &&
                angular.isUndefined(vm.Content.getCurrent().notifyAuthor)
            ) {
                /**
                 * When the user starts adding feeds to notify,
                 * set the content's notifyAuthor fields to true.
                 */
                vm.Content.getCurrent().notifyAuthor = true;
            }
        },
        true,
    );

    /**
     * Save content is broadcasted, do the save.
     */
    $scope.$on('save-content', (evt, asDraft) => {
        if (_isSaving) {
            return;
        }

        // The selected feeds to notify. Defaut to empty array
        const feedsToNotify = vm.Content.getCurrent().notifyFeedKeys || [];
        // Check if the user has the rights to notify and if some feeds have been selected
        const shouldNotify = vm.canNotifyUsers() && feedsToNotify.length > 0;
        // Set notifyUsers to true if the above conditions are fulfilled.
        vm.Content.getCurrent().notifyUsers = shouldNotify;
        /*
         * Only keep the vm.Content.getCurrent().notifyAuthor value if notification conditions are fulfilled.
         * If not, set to false.
         */
        vm.Content.getCurrent().notifyAuthor = shouldNotify && vm.Content.getCurrent().notifyAuthor;
        /**
         * If some feeds have been selected to be notified,
         * don't save and show a confirmation dialog first.
         */
        if (!asDraft && vm.Content.getCurrent().notifyUsers) {
            // Format the keys
            vm.feedsToNotify = feedKeyToFeed(feedsToNotify);
            vm.openNotificationAlert();
        } else {
            submitSaveContent(asDraft);
        }
    });

    $scope.$on('abstract-picker__close-end', function onDialogCloseEnd(evt, dialogId) {
        if (dialogId === 'media-picker-content-thumbnail') {
            $scope.$apply(() => {
                _refreshThumbnail();
            });
        }
    });

    $rootScope.$on('update-content-thumbnail', function onThumbnailUpdate(evt) {
        $timeout(() => {
            $scope.$apply(() => {
                _refreshThumbnail();
            });
        });
        onUploadImageDialogClose();
    });

    $rootScope.$on('delete-content-thumbnail', (evt, contentId) => {
        if (Content.getCurrent().id !== contentId) {
            return;
        }

        $timeout(() => {
            vm.featuredThumbnail = null;
            UploaderAction.clear(vm.contentId);
        });
    });

    $rootScope.$on('cropper-request', () => {
        vm.openCropper();
    });

    // ///////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        const currentContent = Content.getCurrent() || {};
        if (!currentContent.endDate) {
            delete currentContent.endDate;
        }
        if (!currentContent.startDate) {
            delete currentContent.startDate;
        }
        if (!currentContent.featuredEndDate) {
            delete currentContent.featuredEndDate;
        }
        if (!currentContent.featuredStartDate) {
            delete currentContent.featuredStartDate;
        }
        const customContentTypeId = currentContent.customContentType || get(Template.getCurrent(), 'customContentType');
        vm.customContentTypeId = customContentTypeId;

        vm.contentId = currentContent.id || generateUUID();
        // eslint-disable-next-line no-undef
        vm.lifeCycleFirstConfig = angular.isUndefinedOrEmpty(get(currentContent, 'properties.lastLifeCycle'));
        vm.restrictToFeeds = UserAccess.isEditor()
            ? undefined
            : UserAccess.getFeedsForAction('CUSTOM_CONTENT_EDIT', {
                  customContentTypeId,
              });

        _refreshFormattedDates(currentContent);

        // eslint-disable-next-line no-undef
        if (angular.isUndefined(currentContent.properties)) {
            currentContent.properties = {};
        }

        // eslint-disable-next-line no-undef
        if (angular.isUndefined(currentContent.endDateInput)) {
            currentContent.endDateInput = {};
        }

        // eslint-disable-next-line no-undef
        if (angular.isUndefined(currentContent.customContentTypeDetails)) {
            currentContent.customContentTypeDetails = {};
        }

        // eslint-disable-next-line no-undef
        if (angular.isDefined(customContentTypeId)) {
            vm.currentCustomContentType = CustomContentType.get(customContentTypeId);
            _handleDataLifeCycle(vm.currentCustomContentType);
        }

        // eslint-disable-next-line no-undef
        if (angular.isDefinedAndFilled(currentContent.id)) {
            currentContent.isDefaultContentList =
                get(Instance.getInstance(), `defaultContentLists.${currentContent.customContentType}`) ===
                currentContent.id;
        }

        $timeout(() => {
            _canEditRights();
        });

        /*
         * Note: this may be triggered several times in basic mode, so do not always override currentContent with
         * whatever is in template.
         */
        if (Content.getAction() === 'create') {
            // eslint-disable-next-line no-undef
            const template = angular.fastCopy(currentContent.template);

            if (currentContent.type !== 'template') {
                const tempMetadata = currentContent.metadata || get(template, 'metadata', []);
                set(currentContent, 'metadata', tempMetadata);

                Metadata.formatMetadataForServer(currentContent.template);
                // Do not apply a fastCopy here to update the object whenever the metadata are fully loaded
                Metadata.formatMetadataForClient(currentContent, false);
            }

            currentContent.feedKeys = currentContent.feedKeys || template.feedKeys;

            currentContent.featuredFeedKeys = currentContent.featuredFeedKeys || template.featuredFeedKeys;

            currentContent.customContentTypeTags =
                currentContent.customContentTypeTags || template.customContentTypeTags;

            vm.focusTitle = true;
        }

        // Other context than a pure content.

        // Set widget context, this add some widget exclusive to some context.
        // eslint-disable-next-line no-param-reassign
        Widget.context = undefined;
        if (currentContent.type === InitialSettings.CONTENT_TYPES.COMMUNITY) {
            // eslint-disable-next-line no-param-reassign
            Widget.context = [InitialSettings.CONTENT_TYPES.COMMUNITY];
        }

        vm.visibleByIds = currentContent.feedKeys ? currentContent.feedKeys : [];
        vm.highlightedForIds = currentContent.feedKeys ? currentContent.featuredFeedKeys : [];

        const hasGenAIFF = Features.hasFeature('generative-ai');
        if (hasGenAIFF) {
            vm.imageUploadOptions = [
                ...vm.imageUploadOptions,
                { type: 'generate-with-ai', onConfirm: onUploadFromComputerConfirm },
            ];
        }

        _refreshThumbnail();
    }

    init();
}

// ///////////////////////////

// eslint-disable-next-line no-undef
angular.module('Controllers').controller('ContentSidebarSettingsController', ContentSidebarSettingsController);
