import get from 'lodash/get';
import includes from 'lodash/includes';
import loFind from 'lodash/find';
import throttle from 'lodash/throttle';

import { generateUUID } from '@lumapps/utils/string/generateUUID';

/////////////////////////////

function ContentSidebarComponentController(
    $rootScope,
    $scope,
    AbstractPicker,
    Cell,
    Config,
    ConfigInstance,
    ConfigTheme,
    Content,
    ContentTemplate,
    Customer,
    Features,
    FormValidation,
    Header,
    InitialSettings,
    Instance,
    LxDialogService,
    LxNotificationService,
    Media,
    Row,
    Style,
    Translation,
    User,
    UserAccess,
    Utils,
    Widget,
    WidgetSettingsConstant,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Private attributes   //
    //                         //
    /////////////////////////////

    /**
     * The translate keys for confirm dialogs.
     *
     * @type {Object}
     * @constant
     */
    const _EDITABLE_TYPES = {
        GLOBAL_STYLE: {
            edit: 'STYLE_EDIT',
            help: 'STYLE_EDIT_HELP',
        },
        GLOBAL_WIDGET: {
            edit: 'GLOBAL_WIDGET_EDIT',
            help: 'GLOBAL_WIDGET_EDIT_HELP',
        },
    };

    /**
     * The translate keys for confirm dialogs.
     *
     * @type {Object}
     * @constant
     */
    const _DELETABLE_TYPES = {
        GLOBAL_STYLE: {
            delete: 'STYLE.DELETE_GLOBAL_STYLE',
            help: 'STYLE.DELETE_GLOBAL_STYLE_HELPER',
        },
    };

    /**
     * The list of the widgets which can't be global.
     *
     * @type {Array}
     * @constant
     * @readonly
     */
    const _GLOBAL_WIDGET_EXCLUSION = [
        InitialSettings.WIDGET_TYPES.STANDALONE_QUESTION,
        InitialSettings.WIDGET_TYPES.TODO,
        InitialSettings.WIDGET_TYPES.CONTRIBUTION,
        InitialSettings.WIDGET_TYPES.TITLE,
        InitialSettings.WIDGET_TYPES.SUMMARY,
    ];

    /**
     * Time (in ms) after which the global widget can be saved again.
     */
    const _SAVING_GLOBAL_WIDGET_THROTTLE_TIME = 2000;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The current sidebar form.
     *
     * @type {Object}
     */
    vm.form = {};

    /**
     * The global style dialog id.
     *
     * @type {string}
     * @constant
     */
    vm.GLOBAL_STYLE_DIALOG_ID = 'global-style-dialog';

    /**
     * The global widget dialog id.
     *
     * @type {string}
     * @constant
     */
    vm.GLOBAL_WIDGET_DIALOG_ID = 'global-widget-dialog';

    /**
     * The global widget settings.
     *
     * @type {Object}
     */
    vm.globalWidgetSettings = {};

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Cell = Cell;
    vm.Config = Config;
    vm.ConfigTheme = ConfigTheme;
    vm.Content = Content;
    vm.Customer = Customer;
    vm.Features = Features;
    vm.FormValidation = FormValidation;
    vm.Header = Header;
    vm.Instance = Instance;
    vm.Media = Media;
    vm.Row = Row;
    vm.Style = Style;
    vm.Translation = Translation;
    vm.User = User;
    vm.UserAccess = UserAccess;
    vm.Utils = Utils;
    vm.Widget = Widget;
    vm.WidgetSettingsConstant = WidgetSettingsConstant;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if the current style is defined and has status 'LIVE'.
     *
     * @return {boolean} Whether the current style is defined and not deleted.
     */
    function _isCurrentStyleLive() {
        const currentStyle = Style.getCurrent('widget');

        return angular.isDefined(currentStyle) && currentStyle.status === 'LIVE';
    }

    /**
     * LxConfirm to prevent user mistakes.
     *
     * @param {string}   [type] The type of notification, use it to set translations variables.
     * @param {Function} [cb]   The callback function to call on user positive answer.
     */
    function _confirmGlobalEdit(type, cb) {
        cb = cb || angular.noop;
        type = type || _EDITABLE_TYPES.GLOBAL_STYLE;

        LxNotificationService.confirm(
            Translation.translate(type.edit),
            Translation.translate(type.help),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onUserAction(answer) {
                if (answer) {
                    cb();
                }
            },
        );
    }

    /**
     * LxConfirm to prevent user mistakes.
     *
     * @param {string}   [type] The type of notification, use it to set translations variables.
     * @param {Function} [cb]   The callback function to call on user positive answer.
     */
    function _confirmGlobalDelete(type, cb) {
        cb = cb || angular.noop;
        type = type || _DELETABLE_TYPES.GLOBAL_STYLE;

        LxNotificationService.confirm(
            Translation.translate(type.delete),
            Translation.translate(type.help),
            {
                cancel: Translation.translate('CANCEL'),
                ok: Translation.translate('OK'),
            },
            function onUserAction(answer) {
                if (answer) {
                    cb();
                }
            },
        );
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Add global style.
     */
    function addGlobalStyle() {
        Style.setCurrent({
            customer: Customer.getCustomerId(),
            instance: Instance.getCurrentInstanceId(),
            type: 'widget',
        });

        Utils.waitForAndExecute(`#${vm.GLOBAL_STYLE_DIALOG_ID}`);
    }

    /**
     * Add global widget.
     */
    function addGlobalWidget() {
        vm.globalWidgetSettings = {
            isNew: true,
        };

        Utils.waitForAndExecute(`#${vm.GLOBAL_WIDGET_DIALOG_ID}`);
    }

    /**
     * Close component settings.
     */
    function closeComponentSettings() {
        $rootScope.$broadcast('close-component-settings');
    }

    /**
     * Delete component.
     */
    function deleteComponent() {
        const contentTemplate = Content.getCurrent().template;

        if (Widget.getCurrent() && Widget.isActive) {
            Widget.deleteCurrent(contentTemplate);
        }

        if (Cell.getCurrent() && Cell.isActive) {
            Cell.deleteCurrent(contentTemplate);
        }

        if (Row.getCurrent() && Row.isActive) {
            Row.deleteCurrent(contentTemplate);
        }
    }

    /**
     * Deletes the currently selected global style.
     */
    function deleteGlobalStyle() {
        _confirmGlobalDelete(_DELETABLE_TYPES.GLOBAL_STYLE, () => {
            const currentStyle = Style.getCurrent('widget');
            const currentWidget = Widget.getCurrent();

            Style.del(
                currentStyle.uid,
                () => {
                    const deletedStyle = { ...currentStyle, status: 'DELETED' };

                    // Mark current style as deleted.
                    Style.setCurrent(deletedStyle, 'widget');
                    $rootScope.$broadcast('widget-style', get(currentWidget, 'uuid'), deletedStyle, true);
                    LxNotificationService.success(Translation.translate('STYLE.DELETE_GLOBAL_STYLE_SUCCESS'));
                },
                Utils.displayServerError,
                'widget',
            );
        });
    }

    /**
     * Display component delete button.
     *
     * @return {boolean} Whether the delete button is displayed or not.
     */
    function displayComponentDeleteButton() {
        const contentType = Content.getCurrent().type;

        return !Header.isActive && (contentType === 'template' || (Content.isCurrentDesignerMode('NEW_SIMPLE_BUILDER_MODE') || Content.isCurrentDesignerMode('NEW_SIMPLE_EXPERT_MODE') || Content.isCurrentDesignerMode('FULL_LEGACY_DESIGNER')));
    }

    /**
     * Display widget button.
     *
     * @param  {string}  type         The type of content the user is editing.
     * @param  {boolean} isEditAction If the edit action is set or not.
     * @return {boolean} Whether the button is displayed or not.
     */
    function displayWidgetButton(type, isEditAction) {
        const currentWidget = Widget.getCurrent();
        const isCurrentStyleLive = _isCurrentStyleLive();

        const isGlobalStyleValid =
            type === 'style' &&
            Widget.settingsActiveTab === 1 &&
            ((!isEditAction && !isCurrentStyleLive) || (isEditAction && isCurrentStyleLive));

        /**
         * We are refering to a list of exceptions for the widgets that can't be set as global anymore,
         * but if a widget from this list is already global (from a previous configuration), 
         * we are enabling the save/edit of its global settings.
         */
        const isGlobalWidgetValid =
            type === 'widget' &&
            angular.isDefinedAndFilled(currentWidget) &&
            (!includes(_GLOBAL_WIDGET_EXCLUSION, currentWidget.widgetType) || currentWidget.isGlobal) &&
            ((!isEditAction && !currentWidget.id) || (isEditAction && currentWidget.id));

        const isUserAllowed = currentWidget && Widget.isActive && UserAccess.isUserAllowed('GLOBAL_WIDGET_EDIT');

        return isUserAllowed && (isGlobalStyleValid || isGlobalWidgetValid);
    }

    /**
     * Duplicate the current cell.
     */
    function duplicateCell() {
        const currentContent = Content.getCurrent();
        const currentCell = Cell.getCurrent();

        if (get(currentContent, 'template.fixedLayout')) {
            return;
        }

        const currentCellContext = ContentTemplate.getElementContext(currentContent, 'cell', 'uuid', currentCell.uuid);

        const duplicatedCell = angular.fastCopy(currentCell);
        // Reset cells uuids.
        ContentTemplate.parseCell(
            duplicatedCell,
            {
                multiple: true,
                type: ['row', 'cell', 'widget'],
                removeMatchingItems: function onRemove(item) {
                    return item.type === 'widget' && item.widgetType === InitialSettings.WIDGET_TYPES.CONTRIBUTION;
                },
                updateElement: function onUpdateElement(item) {
                    item.uuid = generateUUID();
                },
            },
            currentCellContext.parent,
            currentCellContext.index,
        );

        const currentCellsFromParent = get(currentCellContext, 'parent.cells', []);

        if (currentCellsFromParent.length <= 3) {
            currentCellsFromParent.splice(currentCellContext.index, 0, duplicatedCell);

            angular.forEach(currentCellsFromParent, function forEachCell(cellItem) {
                cellItem.width = 12 / currentCellsFromParent.length;
            });

            $scope.$broadcast('cell-add');
        } else {
            LxNotificationService.error(Translation.translate('CELL_ERROR_MAX_CELLS'));
        }
    }

    /**
     * Duplicate the current row.
     */
    function duplicateRow() {
        const currentContent = Content.getCurrent();
        const currentRow = Row.getCurrent();

        if (get(currentContent, 'template.fixedLayout')) {
            return;
        }

        const currentRowContext = ContentTemplate.getElementContext(currentContent, 'row', 'uuid', currentRow.uuid);

        const duplicatedRow = angular.fastCopy(currentRow);

        // Reset cells uuids.
        ContentTemplate.parseRow(
            duplicatedRow,
            {
                multiple: true,
                type: ['row', 'cell', 'widget'],
                removeMatchingItems: function onRemove(item) {
                    return item.type === 'widget' && item.widgetType === InitialSettings.WIDGET_TYPES.CONTRIBUTION;
                },
                updateElement: function onUpdateElement(item) {
                    item.uuid = generateUUID();
                },
            },
            currentRowContext.parent,
            currentRowContext.index,
        );

        get(currentRowContext.parent, 'components', []).splice(currentRowContext.index, 0, duplicatedRow);
    }

    /**
     * Edit current global style.
     */
    function editGlobalStyle() {
        _confirmGlobalEdit(_EDITABLE_TYPES.GLOBAL_STYLE, function onConfirmGlobalStyleEdit() {
            Style.setCurrent(Style.getCurrent('widget'));

            if (!Style.getCurrent().instance) {
                Style.getCurrent().properties.isCustomer = true;
            }

            Utils.waitForAndExecute(`#${vm.GLOBAL_STYLE_DIALOG_ID}`);
        });
    }

    /**
     * Edit current global widget.
     */
    function editGlobalWidget() {
        _confirmGlobalEdit(_EDITABLE_TYPES.GLOBAL_WIDGET, function onConfirmGlobalWidgetEdit() {
            vm.globalWidgetSettings = Widget.getCurrent().properties.global;
            vm.globalWidgetSettings.isNew = false;

            Utils.waitForAndExecute(`#${vm.GLOBAL_WIDGET_DIALOG_ID}`);
        });
    }

    /**
     * Get widget settings template path.
     *
     * @return {string} The widget settings template path.
     */
    function getWidgetSettingsPath() {
        const currentWidget = Widget.getCurrent();
        let rootPath = '/client/front-office/';
        const overrideBasePath = `specifics/overrides/${Instance.getInstance().override}/`;
        const backwardCompatibleWidgetType = Widget.getBackwardCompatibleWidgetType(currentWidget.widgetType);

        const widgetSettingInConfigInstance = loFind(ConfigInstance.AVAILABLE_WIDGETS, {
            widgetType: currentWidget.widgetType,
        });
        currentWidget.isOverride = get(widgetSettingInConfigInstance, 'isOverride', false);

        if (currentWidget.isOverride) {
            rootPath += overrideBasePath;
        } else {
            const overrideConfigInstance = loFind(ConfigInstance.AVAILABLE_WIDGETS, function findOverride(widget) {
                return widget.isOverride && widget.type === currentWidget.widgetType;
            });

            if (angular.isDefinedAndFilled(overrideConfigInstance)) {
                rootPath += overrideBasePath;
            }
        }

        rootPath += `modules/content/modules/widget/modules/widget-${backwardCompatibleWidgetType}/views/partials/widget-${backwardCompatibleWidgetType}-settings.html`;

        return rootPath;
    }

    /**
     * Indicates if the current widget is active or not.
     *
     * @return {boolean} Whether the current widget is active or not.
     */
    function isWidgetActive() {
        return Widget.getCurrent() && Widget.isActive;
    }

    /**
     * Indicates if the current widget is a global widget or not.
     *
     * @return {boolean} Whether the current widget is a global widget or not.
     */
    function isCurrentWidgetGlobal() {
        return Widget.getCurrent() && vm.Widget.getCurrent().isGlobal;
    }

    /**
     * Indicates if the current widget is a remote widget or not.
     *
     * @return {boolean} Whether the current widget is a remote widget or not.
     */
    function isCurrentWidgetRemote() {
        return Widget.getCurrent() && Widget.getCurrent().widgetType === 'remote';
    }

    /**
     * Force a cell to be in plain mode if the sticky property is set to true.
     */
    function onCellStickyChange() {
        const currentCell = Cell.getCurrent();

        if (angular.isUndefinedOrEmpty(currentCell)) {
            return;
        }

        const currentCellProperties = get(currentCell, 'properties', {});

        if (currentCellProperties.sticky) {
            currentCellProperties.plain = true;
        }
    }

    /**
     * When the style of the cell changes, update the properties of the cell.
     *
     * @param {Object} newStyle The new style.
     */
    function onChangeCellStyle(newStyle) {
        Cell.getCurrent().properties.style = newStyle;
    }

    /**
     * When the style of the row changes, update the properties of the row.
     *
     * @param {Object} newStyle The new style.
     */
    function onChangeRowStyle(newStyle) {
        Row.getCurrent().properties.style = newStyle;
    }

    /**
     * Open media picker for component background image.
     *
     * @param {string} selectorId The selector picker id to open.
     */
    function openMediaPicker(selectorId) {
        Utils.waitForAndExecute(selectorId, AbstractPicker);
    }

    /**
     * Quickly save the current global style.
     */
    function quickSaveGlobalStyle() {
        const currentWidgetStyle = Style.getCurrent('widget');
        const currentWidget = Widget.getCurrent();

        _confirmGlobalEdit(_EDITABLE_TYPES.GLOBAL_STYLE, function onConfirmGlobalStyleEdit() {
            currentWidgetStyle.properties = angular.fastCopy(get(currentWidget, 'properties.style'));

            Style.save(
                Style.cleanWidgetGlobalStyle(currentWidgetStyle),
                function saveCallBack(response) {
                    $rootScope.$broadcast('widget-style', get(currentWidget, 'uuid'), response);

                    LxNotificationService.success(Translation.translate('STYLE_SAVE_SUCCESS'));
                },
                Utils.displayServerError,
                'widget',
            );
        });
    }

    /**
     * Quickly save the current global widget.
     */
    function quickSaveGlobalWidget() {
        _confirmGlobalEdit(_EDITABLE_TYPES.GLOBAL_WIDGET, function onEditConfirm() {
            vm.saveGlobalWidget(true);
        });
    }

    /**
     * Save the global style.
     */
    function saveGlobalStyle() {
        if (vm.form.style.$valid) {
            const currentStyle = Style.getCurrent();
            const currentWidget = Widget.getCurrent();

            if (angular.isDefinedAndFilled(get(currentStyle, 'properties.isCustomer'))
                && currentStyle.properties.isCustomer) {
                currentStyle.instance = undefined;
                currentStyle.isDefault = false;
            }

            currentStyle.properties = angular.copy(get(currentWidget, 'properties.style'));

            Style.save(
                Style.cleanWidgetGlobalStyle(currentStyle),
                function onSaveSuccess(response) {
                    currentWidget.style = response.id;

                    $rootScope.$broadcast('widget-style', get(currentWidget, 'uuid'), response);

                    LxNotificationService.success(Translation.translate('STYLE_SAVE_SUCCESS'));
                    LxDialogService.close(vm.GLOBAL_STYLE_DIALOG_ID);
                },
                Utils.displayServerError,
                'widget',
            );
        } else {
            if (vm.form.style) {
                FormValidation.setFormDirty(vm.form.style);
            }

            LxNotificationService.error(Translation.translate('ERROR_CHECK_FORM'));
        }
    }

    /**
     * Save global widget.
     *
     * @param {boolean}  passFormCheck Useful to save an existing global widget quickly.
     * @param {Function} [cb]          The callback to call after save success.
     */
    function saveGlobalWidget(passFormCheck, cb) {
        cb = cb || angular.noop;

        if (passFormCheck || get(vm.form, 'globalWidget.$valid')) {
            const currentWidget = Widget.getCurrent();

            if (passFormCheck) {
                vm.globalWidgetSettings = {};
            } else {
                currentWidget.isGlobal = true;
                currentWidget.properties.global = vm.globalWidgetSettings;
            }

            if (vm.globalWidgetSettings.isNew) {
                currentWidget.customer = Customer.getCustomerId();
                currentWidget.instance = Instance.getCurrentInstanceId();

                delete currentWidget.id;
                delete currentWidget.key;
                delete currentWidget.uid;
            }

            Widget.saveGlobalWidget(function onSaveSuccess() {
                LxNotificationService.success(Translation.translate('GLOBAL_WIDGET_SAVE_SUCCESS'));

                if (!passFormCheck) {
                    LxDialogService.close(vm.GLOBAL_WIDGET_DIALOG_ID);
                }

                cb();
            }, Utils.displayServerError);
        } else {
            if (vm.form.globalWidget) {
                FormValidation.setFormDirty(vm.form.globalWidget);
            }

            LxNotificationService.error(Translation.translate('ERROR_CHECK_FORM'));
        }
    }

    /**
     * Set component active.
     *
     * @param {string} componentType The type of component to activate.
     */
    function setComponentActive(componentType) {
        switch (componentType) {
            case 'row':
                Row.isFocused = false;
                Row.isActive = true;

                if (angular.isDefined(Cell.getCurrent())) {
                    Cell.isFocused = true;
                    Cell.isActive = false;
                }

                if (angular.isDefined(Widget.getCurrent())) {
                    Widget.isFocused = true;
                    Widget.isActive = false;
                }

                break;

            case 'cell':
                Row.isFocused = true;
                Row.isActive = false;

                Cell.isFocused = false;
                Cell.isActive = true;

                if (angular.isDefined(Widget.getCurrent())) {
                    Widget.isFocused = true;
                    Widget.isActive = false;
                }

                break;

            case 'widget':
            // eslint-disable-next-line no-fallthrough, padding-line-between-statements
            default:
                Row.isFocused = true;
                Row.isActive = false;

                Cell.isFocused = true;
                Cell.isActive = false;

                Widget.isFocused = false;
                Widget.isActive = true;

                break;
        }
    }

    /**
     * Start the onboarding tour of the new designer.
     * There is one onboarding tour for the cell styles, one for the row styles and one for the while widget styles.
     *
     * TODO [Clément]: this must be removed when the new designer onboarding tours are removed.
     *
     * @param {string} type The type of component.
     *                      Possible values are: 'cell', 'row' or 'widget'.
     */
    function startNewDesignerTour(type) {
        if (angular.isUndefinedOrEmpty(type)) {
            return;
        }

        $rootScope.$broadcast('start-tour', `TOUR_NEW_${type.toUpperCase()}_STYLE_DESIGNER`);
    }

    /////////////////////////////

    vm.addGlobalStyle = addGlobalStyle;
    vm.addGlobalWidget = addGlobalWidget;
    vm.closeComponentSettings = closeComponentSettings;
    vm.deleteComponent = deleteComponent;
    vm.deleteGlobalStyle = deleteGlobalStyle;
    vm.displayComponentDeleteButton = displayComponentDeleteButton;
    vm.displayWidgetButton = displayWidgetButton;
    vm.duplicateCell = duplicateCell;
    vm.duplicateRow = duplicateRow;
    vm.isWidgetActive = isWidgetActive;
    vm.isCurrentWidgetGlobal = isCurrentWidgetGlobal;
    vm.isCurrentWidgetRemote = isCurrentWidgetRemote;
    vm.editGlobalStyle = editGlobalStyle;
    vm.editGlobalWidget = editGlobalWidget;
    vm.getWidgetSettingsPath = getWidgetSettingsPath;
    vm.onCellStickyChange = onCellStickyChange;
    vm.onChangeCellStyle = onChangeCellStyle;
    vm.onChangeRowStyle = onChangeRowStyle;
    vm.openMediaPicker = openMediaPicker;
    vm.quickSaveGlobalStyle = quickSaveGlobalStyle;
    vm.quickSaveGlobalWidget = quickSaveGlobalWidget;
    vm.saveGlobalStyle = saveGlobalStyle;
    vm.saveGlobalWidget = throttle(saveGlobalWidget, _SAVING_GLOBAL_WIDGET_THROTTLE_TIME);
    vm.setComponentActive = setComponentActive;
    vm.startNewDesignerTour = startNewDesignerTour;

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the global style dialog closes, remove the current style from the service.
     *
     * @param {Event}  evt      The dialog close end event.
     * @param {string} dialogId The id of the dialog that closes.
     */
    $scope.$on('lx-dialog__close-end', function onDialogCloseEnd(evt, dialogId) {
        if (dialogId === vm.GLOBAL_STYLE_DIALOG_ID) {
            Style.setCurrent(undefined);
        }
    });
}

angular.module('Controllers').controller('ContentSidebarComponentController', ContentSidebarComponentController);

/////////////////////////////

export { ContentSidebarComponentController };
