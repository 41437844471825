import some from 'lodash/some';

/////////////////////////////

function AdminController(
    $scope,
    $state,
    Config,
    ConfigInstance,
    Content,
    CustomContentType,
    Customer,
    Directory,
    Features,
    Instance,
    Style,
    Template,
    User,
    UserAccess,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * An object holding the visibility of each custom content type for the current user in the menu.
     *
     * @type {Object}
     */
    vm.canViewCustomContentType = {};

    /**
     * Indicates if the user can see at least one custom content type to edit.
     *
     * @type {boolean}
     */
    vm.canViewCustomContentTypes = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Config = Config;
    vm.ConfigInstance = ConfigInstance;
    vm.CustomContentType = CustomContentType;
    vm.Customer = Customer;
    vm.Directory = Directory;
    vm.Features = Features;
    vm.Instance = Instance;
    vm.Style = Style;
    vm.User = User;
    vm.UserAccess = UserAccess;
    vm.Utils = Utils;

    /////////////////////////////
    //                         //
    //    Private functions    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if any custom content type is visible by the current user.
     *
     * @return {boolean} Whether the user can see any custom content type or not.
     */
    function _canSeeAnyCustomContentType() {
        // eslint-disable-next-line you-dont-need-lodash-underscore/some
        vm.canViewCustomContentTypes = some(vm.canViewCustomContentType, (customContentTypeVisibility) => {
            return customContentTypeVisibility;
        });

        return vm.canViewCustomContentTypes;
    }

    /**
     * Sets the visibility for each custom content type for the currently connected user.
     */
    function _setCustomContentTypeVisibility() {
        angular.forEach(
            CustomContentType.displayList(CustomContentType.CURRENT_INSTANCE_CCT_LIST_KEY),
            (customContentType) => {
                vm.canViewCustomContentType[customContentType.uid] = UserAccess.isUserAllowed(
                    [
                        'CUSTOM_CONTENT_EDIT',
                        'CUSTOM_CONTENT_PUBLISH',
                        'CUSTOM_CONTENT_ARCHIVE',
                        'CUSTOM_CONTENT_DELETE',
                    ],
                    {
                        arrayMethodName: 'some',
                        customContentTypeId: customContentType.uid,
                    },
                );
            },
        );

        _canSeeAnyCustomContentType();
    }

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Check if the current user can access to the analytics admin.
     *
     * @return {boolean} Whether the current user view at least one  items of the analytics admin.
     */
    function canViewAnalyticsAdmin() {
        return (
            // For user admin.
            User.getConnected().isSuperAdmin &&
            Features.hasOneOfFeatures([
                'extended-lumapps-analytics',
            ])
        );
    }

    /**
     * Check if a custom content type is active.
     *
     * @param  {string}  customContentTypeId The custom content type id to check.
     * @return {boolean} Returns true if the custom content type is active.
     */
    function isCustomContentTypeActive(customContentTypeId) {
        return (
            (angular.isDefinedAndFilled($state.params.uid) && $state.params.uid === customContentTypeId) ||
            (angular.isDefinedAndFilled($state.params.customContentType) &&
                $state.params.customContentType === customContentTypeId)
        );
    }

    /**
     * Check if current path name is equal to param name.
     * This is usefull to know which view we are in.
     *
     * @param  {string}  pathName The name to compare with the current state.
     * @return {boolean} If the current state is equal to the param.
     */
    function isCurrentSelected(pathName) {
        return angular.isDefined(pathName) && angular.isDefined($state.current) && $state.current.name === pathName;
    }

    /**
     * Check if a directory is active.
     *
     * @param  {string}  directoryId The directory id to check.
     * @return {boolean} Returns true if the directory is active.
     */
    function isDirectoryActive(directoryId) {
        return angular.isDefinedAndFilled($state.params.id) && $state.params.id === directoryId;
    }

    /////////////////////////////

    vm.canViewAnalyticsAdmin = canViewAnalyticsAdmin;
    vm.isCurrentSelected = isCurrentSelected;
    vm.isCustomContentTypeActive = isCustomContentTypeActive;
    vm.isDirectoryActive = isDirectoryActive;
    vm.currentView = $state.current.name;
    vm.currentParams = $state.params;

    $scope.$on('$stateChangeSuccess', function onStateChangeStart(evt, toState) {
        vm.currentView = $state.current.name;
        vm.currentParams = $state.params;
    });

    /////////////////////////////
    //                         //
    //          Events         //
    //                         //
    /////////////////////////////

    /**
     * When the list of custom content types is updated, go through the visibility for each CCT.
     *
     * @param {Event} evt The original event triggering this method.
     */
    $scope.$on('custom-content-type-list-updated', function onCustomContentTypeListUpdate() {
        _setCustomContentTypeVisibility();
    });

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    function init() {
        Content.setCurrent(undefined);
        Template.setCurrent(undefined);

        _setCustomContentTypeVisibility();
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('AdminController', AdminController);

/////////////////////////////

export { AdminController };
