function IdentityProviderFactory($resource, Config) {
    'ngInject';

    return $resource(
        `${Config.HAUSSMANN_HOST}/_ah/api/lumsites/v1/customer/identityprovider/:methodKey`,
        {},
        {
            delete: {
                isArray: false,
                method: 'DELETE',
                params: {
                    methodKey: 'delete',
                },
            },

            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            getSpFile: {
                method: 'GET',
                params: {
                    isArray: false,
                    key: 'sp.xml',
                },
                responseType: 'text',
                url: `${Config.APPLICATION_HOST}/service/:key`,
            },

            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            save: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'save',
                },
            },
        },
    );
}

angular.module('Factories').factory('IdentityProviderFactory', IdentityProviderFactory);

export { IdentityProviderFactory };
