function NewsletterService(Customer, Instance, LumsitesBaseService, NewsletterFactory) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = LumsitesBaseService.createLumsitesBaseService(NewsletterFactory, {
        autoInit: false,
        objectIdentifier: 'uid',
    });

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The default parameters for all the calls to the NewsletterFactory endpoints.
     *
     * @type {Object}
     */
    service.defaultParams = {};

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Call the Api to send the newsletter.
     *
     * @param  {Object}   params The parameters to send to the endpoint.
     * @param  {Function} cb     A callback function to execute on success.
     * @param  {Function} errCb  A callback function to execute on error.
     * @return {Promise}  The promise of the send endpoint of the Newsletter API.
     */
    function sendNewsletter(params, cb, errCb) {
        cb = cb || angular.noop;
        errCb = errCb || angular.noop;

        return NewsletterFactory.send(params, cb, errCb);
    }

    /////////////////////////////

    service.sendNewsletter = sendNewsletter;

    /////////////////////////////

    /**
     * Initialize the service.
     */
    service.init = function init() {
        service.defaultParams = {
            customer: Customer.getCustomerId(),
            instance: Instance.getCurrentInstanceId(),
        };
    };

    service.init();

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('Newsletter', NewsletterService);

/////////////////////////////

export { NewsletterService };
