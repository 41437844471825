/**
 * Newsletter theme default directive. Seems useless.
 */

function NewsletterThemeDefaultDirective() {
    'ngInject';

    return {
        replace: true,
        restrict: 'E',
        templateUrl: '/client/back-office/modules/newsletter/themes/views/newsletter-theme-default.html',
    };
}

/////////////////////////////

angular.module('Directives').directive('newsletterThemeDefault', NewsletterThemeDefaultDirective);

/////////////////////////////

export { NewsletterThemeDefaultDirective };
