function NewsletterDetailsController(Newsletter) {
    'ngInject';

    const vm = this;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Newsletter = Newsletter;
}

/////////////////////////////

angular.module('Controllers').controller('NewsletterDetailsController', NewsletterDetailsController);

/////////////////////////////

export { NewsletterDetailsController };
