import clone from 'lodash/clone';

import { Text, Topic } from '../types';

export function setTitle(dita: Topic, title: string) {
    const titleNodeIndex = dita?.children?.findIndex((el) => el.name === 'title');
    if (titleNodeIndex === -1) {
        return dita;
    }

    const editedDita = clone(dita);
    (editedDita.children[titleNodeIndex].children[0] as Text).value = title;

    return editedDita;
}
