import { getAttributes } from '@lumapps/data-attributes';

function GlobalSettingsController($scope, Features, Translation) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * Indicates if the Floating Action Button is disabled (while saving) or not.
     *
     * @type {boolean}
     */
    vm.isFabDisabled = false;

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.Features = Features;

    vm.getAttributes = getAttributes('platform-settings');

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Create a new item in the current tab.
     * In fact, the create function will only trigger an event, to tell the controller handling the tab to create a
     * new item.
     */
    function create() {
        $scope.$broadcast('customer-settings_create');
    }

    /**
     * Save the settings of the currently active tab.
     * In fact, the save function will only trigger an event, disable the save button and wait for the controller
     * really handling the save to send back an event when the save is done. The save button will then be enabled
     * back.
     */
    function save() {
        if (vm.isFabDisabled) {
            return;
        }

        vm.isFabDisabled = true;

        let listener = $scope.$on('customer-settings_save-end', () => {
            vm.isFabDisabled = false;

            if (angular.isFunction(listener)) {
                listener();
                listener = undefined;
            }
        });

        $scope.$broadcast('customer-settings_save');
    }

    /////////////////////////////

    vm.create = create;
    vm.Translation = Translation;
    vm.save = save;

    /**
     * Initialize the controller.
     */
    function init() {

    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('GlobalSettingsController', GlobalSettingsController);

/////////////////////////////

export { GlobalSettingsController };
