export enum ANALYTICS {
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=79293141
     */
    DISPLAY_HELPER_LABEL = 'ANALYTICS.DISPLAY_HELPER_LABEL',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=60218653
     */
    SHOW_X_MORE_VALUES = 'ADMIN.ANALYTICS.SHOW_X_MORE_VALUES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=60219114
     */
    HIDE_X_MORE_VALUES = 'ADMIN.ANALYTICS.HIDE_X_MORE_VALUES',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=43442504
     */
    SAMPLING_PERIOD_DAY = 'ADMIN.ANALYTICS.SAMPLING_PERIOD.DAY',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=43442503
     */
    SAMPLING_PERIOD_WEEK = 'ADMIN.ANALYTICS.SAMPLING_PERIOD.WEEK',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=43442445
     */
    SAMPLING_PERIOD_MONTH = 'ADMIN.ANALYTICS.SAMPLING_PERIOD.MONTH',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=59990079
     */
    SAMPLE_PERIOD = 'ADMIN.ANALYTICS.SAMPLE_PERIOD',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=319225347
     */
    COLLECTION_DISABLED_WARNING = 'ANALYTICS.COLLECTION_DISABLED_WARNING',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=319229347
     */
    COLLECTION_DISABLED_WARNING_DESCRIPTION = 'ANALYTICS.COLLECTION_DISABLED_WARNING_DESCRIPTION',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367975933
     */
    GA_GTM_CODE = 'ANALYTICS.GA_GTM_CODE',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367975982
     */
    GTM_ATTR_PREVIEW = 'ANALYTICS.GTM_ATTRIBUTE_PREVIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=367975986
     */
    GA_ATTR_PREVIEW = 'ANALYTICS.GA_ATTRIBUTE_PREVIEW',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=375246387
     */
    COMPONENT_SETTINGS_GA_GTM_HELP = 'ANALYTICS.COMPONENT_SETTINGS_GA_GTM_HELP',
    /**
     * https://app.lokalise.com/project/9908543959143d288486e6.27339719/?k=396281927
     */
    ENTITY_ANALYTICS_DIALOG_TITLE = 'ANALYTICS.ENTITY_ANALYTICS_DIALOG_TITLE',
}
