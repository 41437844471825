(function IIFE() {
    'use strict';

    /////////////////////////////

    function RevisionService(LumsitesBaseService, RevisionFactory) {
        'ngInject';

        var service = LumsitesBaseService.createLumsitesBaseService(RevisionFactory, {
            autoInit: false,
            objectIdentifier: 'uid',
        });

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Restore a content from a specific version.
         *
         * @param  {Object}   params  Extra parameters to apply to the restore request.
         * @param  {Function} [cb]    A function to execute on restore success.
         * @param  {Function} [errCb] A function to execute on restore error.
         * @return {Promise}  The promise of restoring a specific version.
         */
        function restore(params, cb, errCb) {
            cb = cb || angular.noop;
            errCb = errCb || angular.noop;

            return RevisionFactory.restore(params, cb, errCb);
        }

        /////////////////////////////

        service.restore = restore;

        /////////////////////////////

        return service;
    }

    /////////////////////////////

    angular.module('Services').service('Revision', RevisionService);
})();
