(function IIFE() {
    'use strict';

    /////////////////////////////

    function RevisionFactory($resource, Config) {
        'ngInject';

        return $resource(Config.HAUSSMANN_HOST + '/_ah/api/lumsites/v1/content/revision/:methodKey', {}, {
            get: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'get',
                },
            },

            list: {
                isArray: false,
                method: 'GET',
                params: {
                    methodKey: 'list',
                },
            },

            restore: {
                isArray: false,
                method: 'POST',
                params: {
                    methodKey: 'restore',
                },
            },
        });
    }

    /////////////////////////////

    angular.module('Factories').factory('RevisionFactory', RevisionFactory);
})();
