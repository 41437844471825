import { getDomainFromString } from '@lumapps/router/utils';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function CustomerSettingsController(
        $document,
        $interval,
        $location,
        $q,
        $scope,
        $state,
        $timeout,
        $window,
        Customer,
        Features,
        FormValidation,
        InitialSettings,
        Instance,
        InstanceConstant,
        LxNotificationService,
        Mobile,
        Translation,
        UploaderAction,
        User,
        Utils,
        resolveInitialSetting,
    ) {
        'ngInject';

        // This is here to trick the linter into thinking that the blocking resolve injection is used.
        angular.noop(resolveInitialSetting);

        /////////////////////////////

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Number of attempts for polling the customer.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _CUSTOMER_POLLING_ATTEMPTS = 30 * 3;

        /**
         * Delay for polling the customer.
         *
         * @type {number}
         * @constant
         * @readonly
         */
        var _CUSTOMER_POLLING_DELAY = 2000;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * A list of countries.
         *
         * @type {Array}
         * @constant
         * @readonly
         */
        vm.COUNTRIES = [
            'United States',
            'Afghanistan',
            'Aland Islands',
            'Albania',
            'Algeria',
            'American Samoa',
            'Andorra',
            'Angola',
            'Anguilla',
            'Antarctica',
            'Antigua and Barbuda',
            'Argentina',
            'Armenia',
            'Aruba',
            'Australia',
            'Austria',
            'Azerbaijan',
            'Bahamas',
            'Bahrain',
            'Bangladesh',
            'Barbados',
            'Belarus',
            'Belgium',
            'Belize',
            'Benin',
            'Bermuda',
            'Bhutan',
            'Bolivia',
            'Bosnia and Herzegovina',
            'Botswana',
            'Brazil',
            'British Indian Ocean Territory',
            'Brunei Darussalam',
            'Bulgaria',
            'Burkina Faso',
            'Burundi',
            'Cambodia',
            'Cameroon',
            'Canada',
            'Cape Verde',
            'Caribbean Nations',
            'Cayman Islands',
            'Central African Republic',
            'Chad',
            'Chile',
            'China',
            'Christmas Island',
            'Cocos (Keeling) Islands',
            'Colombia',
            'Comoros',
            'Congo',
            'Cook Islands',
            'Costa Rica',
            "Cote D'Ivoire (Ivory Coast)",
            'Croatia',
            'Cuba',
            'Cyprus',
            'Czech Republic',
            'Democratic Republic',
            'Denmark',
            'Djibouti',
            'Dominica',
            'Dominican Republic',
            'East Timor',
            'Ecuador',
            'Egypt',
            'El Salvador',
            'Equatorial Guinea',
            'Eritrea',
            'Estonia',
            'Ethiopia',
            'Falkland Islands (Malvinas)',
            'Faroe Islands',
            'Federated States',
            'Fiji',
            'Finland',
            'France',
            'French Guiana',
            'French Polynesia',
            'French Southern Territories',
            'Gabon',
            'Gambia',
            'Georgia',
            'Germany',
            'Ghana',
            'Gibraltar',
            'Greece',
            'Greenland',
            'Grenada',
            'Guadeloupe',
            'Guam',
            'Guatemala',
            'Guernsey',
            'Guinea',
            'Guinea-Bissau',
            'Guyana',
            'Haiti',
            'Honduras',
            'Hong Kong',
            'Hungary',
            'Iceland',
            'India',
            'Indonesia',
            'Iran',
            'Iraq',
            'Ireland',
            'Isle of Man',
            'Israel',
            'Italy',
            'Jamaica',
            'Japan',
            'Jersey',
            'Jordan',
            'Kazakhstan',
            'Kenya',
            'Kiribati',
            'Korea',
            'Korea (North)',
            'Kuwait',
            'Kyrgyzstan',
            'Laos',
            'Latvia',
            'Lebanon',
            'Lesotho',
            'Liberia',
            'Libya',
            'Liechtenstein',
            'Lithuania',
            'Luxembourg',
            'Macao',
            'Macedonia',
            'Madagascar',
            'Malawi',
            'Malaysia',
            'Maldives',
            'Mali',
            'Malta',
            'Marshall Islands',
            'Martinique',
            'Mauritania',
            'Mauritius',
            'Mayotte',
            'Mexico',
            'Moldova',
            'Monaco',
            'Mongolia',
            'Montenegro',
            'Montserrat',
            'Morocco',
            'Mozambique',
            'Myanmar',
            'Namibia',
            'Nauru',
            'Nepal',
            'Netherlands',
            'Netherlands Antilles',
            'New Caledonia',
            'New Zealand',
            'Nicaragua',
            'Niger',
            'Nigeria',
            'Niue',
            'Norfolk Island',
            'Northern Mariana Islands',
            'Norway',
            'Oman',
            'Pakistan',
            'Palau',
            'Palestinian Territory',
            'Panama',
            'Papua New Guinea',
            'Paraguay',
            'Peru',
            'Philippines',
            'Pitcairn',
            'Poland',
            'Portugal',
            'Puerto Rico',
            'Qatar',
            'Reunion',
            'Romania',
            'Russian Federation',
            'Rwanda',
            'Saint Helena',
            'Saint Kitts and Nevis',
            'Saint Lucia',
            'Saint Pierre and Miquelon',
            'Saint Vincent and the Grenadines',
            'Samoa',
            'San Marino',
            'Sao Tome and Principe',
            'Saudi Arabia',
            'Senegal',
            'Serbia',
            'Seychelles',
            'Sierra Leone',
            'Singapore',
            'Slovak Republic',
            'Slovenia',
            'Solomon Islands',
            'Somalia',
            'South Africa',
            'Spain',
            'Sri Lanka',
            'Sudan',
            'Suriname',
            'Svalbard and Jan Mayen',
            'Swaziland',
            'Sweden',
            'Switzerland',
            'Syria',
            'Taiwan',
            'Tajikistan',
            'Tanzania',
            'Thailand',
            'Timor-Leste',
            'Togo',
            'Tokelau',
            'Tonga',
            'Trinidad and Tobago',
            'Tunisia',
            'Turkey',
            'Turkmenistan',
            'Turks and Caicos Islands',
            'Tuvalu',
            'Uganda',
            'Ukraine',
            'United Arab Emirates',
            'United Kingdom',
            'Uruguay',
            'Uzbekistan',
            'Vanuatu',
            'Vatican City State (Holy See)',
            'Venezuela',
            'Vietnam',
            'Virgin Islands (British)',
            'Virgin Islands (U.S.)',
            'Wallis and Futuna',
            'Western Sahara',
            'Yemen',
            'Zambia',
            'Zimbabwe',
            'Other',
        ];

        /**
         * A list of instances that can be selected for mobile search.
         *
         * @type {Array}
         */
        vm.allowedMobileSearchInstances = [];

        /**
         * If customer creation is a success.
         *
         * @type {boolean}
         */
        vm.customerCreationSuccess = false;

        /**
         * The wizard form object.
         *
         * @type {Object}
         */
        vm.form = {};

        /**
         * Indicates if setup is finalization phase (call failed, checking the customer state).
         *
         * @type {boolean}
         */
        vm.finalizingSetup = false;

        /**
         * Indicates if the form is used to create a new customer (or to edit an existing one).
         *
         * @type {boolean}
         */
        vm.isNewCustomer = true;

        /**
         * Whether to display the customer settings form or not.
         *
         * @type {boolean}
         */
        vm.showForm = false;

        /**
         * Whether to display the domain / subdomain warning.
         *
         * @type {boolean}
         */
        vm.showDomainWarning = false;

        /**
         * A list of company sizes (number of employees).
         *
         * @type {Array}
         */
        vm.sizes = ['1', '2-10', '11-50', '51-200', '201-500', '501-1000', '1001-5000', '5001-10,000', '10,000+'];

        /////////////////////////////

        /**
         * Services and utilities.
         */
        vm.$location = $location;
        vm.Customer = Customer;
        vm.Features = Features;
        vm.FormValidation = FormValidation;
        vm.Instance = Instance;
        vm.Mobile = Mobile;
        vm.UploaderAction = UploaderAction;
        vm.User = User;
        vm.Utils = Utils;
        vm.location = location;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Build a html <input> element.
         *
         * @param  {string}  inputName  The name of the created input.
         * @param  {string}  inputType  The type of the created input.
         * @param  {string}  inputValue The value of the created input.
         * @return {Element} The newly created DOM element.
         */
        function _createDomInputElement(inputName, inputType, inputValue) {
            var input = $document[0].createElement('input');
            input.type = inputType;
            input.name = inputName;
            input.value = inputValue;

            return input;
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Sets the mobile logo to the customer.
         *
         * @param {Object} file The uploader response file.
         */
        function addMobileLogo(file) {
            vm.customer.mobileCustomization = vm.customer.mobileCustomization || {};

            if (angular.isUndefinedOrEmpty(file) || angular.isUndefinedOrEmpty([file.url, file.blobKey], 'some')) {
                return;
            }

            var isLogoInvalid = Mobile.checkMobileLogo(file);

            vm.highlightMobileLogoHelper = isLogoInvalid;

            if (isLogoInvalid) {
                return;
            }

            vm.customer.mobileCustomization.logo = Mobile.addMobileLogo(file, vm.customer.mobileCustomization.logo);
        }

        /**
         * Reformats the input domain to a naked domain e.g. http://www.lumapps.com ==> lumapps.com.
         */
        function formatDomain() {
            var domain = vm.customer.domain;

            if (angular.isDefinedAndFilled(domain)) {
                domain = getDomainFromString(domain);

                var urlParts = domain.split('.');

                // If it has more than 3 bits in the domain url, display a warning as it may be a subdomain.
                vm.showDomainWarning = urlParts.length >= 3;
            }
        }

        /**
         * Slugify the slug text input entered by the user.
         */
        function formatSlug() {
            if (angular.isDefinedAndFilled(vm.customer.slug)) {
                vm.customer.slug = Utils.slugify(vm.customer.slug);
            }
        }

        /**
         * Redirect to the given url.
         *
         * @param {string} [urlPath="/"] The url we want to redirect the user to.
         */
        function redirect(urlPath) {
            $window.location.href = urlPath || '/';
        }

        /**
         * Register a form so we can reference it easily.
         *
         * @param {Object} scope    The form scope.
         * @param {string} formName The form name.
         */
        function registerForm(scope, formName) {
            $timeout(function registerFormInScope() {
                vm.form[formName] = scope && scope[formName] ? scope[formName] : undefined;
            });
        }

        /**
         * Check and save a customer into datastore.
         *
         * @return {Promise} The promise that resolves or reject according to server response.
         */
        function saveCustomer() {
            var areFieldsFilledIn = angular.isDefined(
                [
                    vm.customer,
                    vm.customer.contactEmail,
                    vm.customer.contactFirstName,
                    vm.customer.contactLastName,
                    vm.customer.contactPhone,
                    vm.customer.googleCustomer,
                    vm.customer.name,
                    vm.customer.slug,
                ],
                'every',
            );
            var hasAdminDetails = angular.isDefined([vm.customer.adminEmail, vm.customer.adminPassword], 'every');
            var hasDomainDetails = angular.isDefined([vm.customer.domain, vm.customer.account], 'every');
            var passwordsMatch = vm.customer.adminPassword === vm.customer.adminRepassword;
            var deferred = $q.defer();

            if (areFieldsFilledIn && (hasDomainDetails || (hasAdminDetails && passwordsMatch))) {
                if (angular.isDefined(vm.customer.licenseExpirationDate)) {
                    vm.customer.licenseExpirationDate = undefined;
                }

                vm.formatSlug();

                Features.applyFeaturesStatus(vm.customer);

                function onCustomerSaveSuccess(response) {
                    Customer.setCustomer(response);

                    vm.customerCreationSuccess = true;
                    vm.showForm = false;
                    vm.error = undefined;

                    if (vm.isNewCustomer) {
                        User.refreshToken(function onRefreshTokenSuccess() {
                            if (vm.customer.googleCustomer) {
                                $window.location.href = InitialSettings.SERVER_BASE_URL;
                            } else {
                                // Auto authenticate user if not google customer.
                                var form = $document[0].createElement('form');
                                form.method = 'post';
                                form.action = '/login';
                                form.appendChild(_createDomInputElement('loginMethod', 'hidden', 'email'));
                                form.appendChild(_createDomInputElement('login', 'email', vm.customer.adminEmail));
                                form.appendChild(
                                    _createDomInputElement('password', 'password', vm.customer.adminPassword),
                                );
                                form.submit();
                            }
                        });
                    }

                    deferred.resolve();
                }

                Customer.save(vm.customer, onCustomerSaveSuccess, function onCustomerSaveError(err) {
                    // Backend is sending a 502 error after 1 minute, catched as 502 or -1.
                    if (!vm.isNewCustomer || (err.status !== -1 && err.status !== 502)) {
                        deferred.reject(Utils.getServerError(err));

                        return;
                    }

                    vm.finalizingSetup = true;
                    var attempts = 0;

                    /*
                     * Check every 2 seconds if install is done.
                     * If install is done, 'defaultInstance' should be sent on customer/get.
                     */
                    var setupInterval = $interval(function checkCustomer() {
                        attempts++;

                        Customer.get(
                            {},
                            function onCustomerGet(response) {
                                // Check if the default instance has been created.
                                if (angular.isDefinedAndFilled(response.defaultInstance)) {
                                    onCustomerSaveSuccess(response);
                                    vm.finalizingSetup = false;
                                    $interval.cancel(setupInterval);
                                }
                            },
                            undefined,
                        );

                        // Stop refreshing after 3 minutes (install should be done).
                        if (attempts === _CUSTOMER_POLLING_ATTEMPTS) {
                            vm.finalizingSetup = false;
                            $interval.cancel(setupInterval);
                            deferred.reject(Utils.getServerError(err));
                        }
                    }, _CUSTOMER_POLLING_DELAY);
                });

                return deferred.promise;
            }

            return deferred.reject();
        }

        /**
         * Update the list of instances that can be used for mobile search.
         */
        function updateMobileSearchInstances() {
            // Update the list of instances that can be selected.
            vm.allowedMobileSearchInstances = _.map(vm.customer.mobileInstanceKeys, function mapKeyToInstance(key) {
                return Instance.instanceKeyToInstance(key);
            });

            // Filter the instances to keep only defined instances.
            vm.allowedMobileSearchInstances = _.filter(vm.allowedMobileSearchInstances, function filterInstance(
                instance,
            ) {
                return angular.isDefinedAndFilled(instance);
            });

            // Remove the selected mobile search instances that are not allowed anymore.
            vm.customer.mobileSearchInstanceKeys = _.intersection(
                vm.customer.mobileSearchInstanceKeys,
                vm.customer.mobileInstanceKeys,
            );
        }

        /**
         * Check if form is valid.
         *
         * @param  {string}  formName The form which need to be validated.
         * @return {boolean} If the form is valid or not.
         */
        function validateForm(formName) {
            var form = vm.form[formName];

            if (
                (angular.isUndefined(form.adminRepassword) ||
                    form.adminPassword.$modelValue === form.adminRepassword.$modelValue) &&
                form.$valid
            ) {
                return true;
            }

            FormValidation.setFormDirty(form);

            return Translation.translate('ADMIN_CUSTOMER_FORM_ERROR');
        }

        /////////////////////////////

        vm.addMobileLogo = addMobileLogo;
        vm.formatDomain = formatDomain;
        vm.formatSlug = formatSlug;
        vm.onStepperComplete = angular.noop;
        vm.redirect = redirect;
        vm.registerForm = registerForm;
        vm.saveCustomer = saveCustomer;
        vm.updateMobileSearchInstances = updateMobileSearchInstances;
        vm.validateForm = validateForm;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            // Hide the ghost header. It will be removed by the settings service.
            angular.element('#ghost-header').css('display', 'none');

            Customer.getCustomer(true).then(function onCustomerFetch(customer) {
                vm.customer = customer || {};

                vm.isNewCustomer = !User.isConnected() && angular.isUndefinedOrEmpty(vm.customer.id);

                if (!vm.isNewCustomer && !_.get(User.getConnected(), 'isSuperAdmin', false)) {
                    LxNotificationService.error(Translation.translate('SERVER_ERROR_NOT_AUTHORIZED'));

                    $state.go('login');
                }

                vm.error = Customer.getError();

                vm.showForm = Customer.getStatus() || angular.isUndefinedOrEmpty(vm.error);

                if (!vm.customer.googleCustomer) {
                    var token = _.get($location.search(), 'token');

                    if (angular.isDefinedAndFilled(token)) {
                        vm.customer.token = token;
                        vm.customer.googleCustomer = false;
                    } else {
                        vm.customer.googleCustomer = true;
                    }
                }

                if (angular.isUndefinedOrEmpty(vm.customer.slug)) {
                    vm.customer.slug = '';
                }

                if (angular.isUndefinedOrEmpty(vm.customer.userProfilePicture)) {
                    vm.customer.userProfilePicture = 'gPlus';
                }

                // For the mobile app, we need to know all the instances from the current platform.
                var allInstancesPromise = Instance.getAllCustomerInstances({
                    params: {
                        sortOrder: 'name',
                    },
                    projection: InstanceConstant.PROJECTION.EMPTY_PARENT,
                });

                allInstancesPromise.then(vm.updateMobileSearchInstances);
            });
        }

        init();
    }

    /////////////////////////////

    angular.module('Controllers').controller('CustomerSettingsController', CustomerSettingsController);
})();
