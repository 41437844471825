(function IIFE() {
    'use strict';

    /////////////////////////////

    function MobileService(LxNotificationService, Translation) {
        'ngInject';

        var service = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Defines the mobile logo's specifications, the logo should have a
         * width of 480px, an height of 96px and should not weight more than 300ko.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        var _MOBILE_LOGO_SPECIFICATIONS = {
            height: 96,
            size: 300 * 1000,
            type: 'image/png',
            width: 480,
        };

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The mobile logo possible file type.
         *
         * @type {Array}
         * @constant
         * @readonly
         */
        service.MOBILE_LOGO_ALLOWED_FILE_TYPES = [_MOBILE_LOGO_SPECIFICATIONS.type];

        /**
         * The mobile logo uploader id.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        service.MOBILE_LOGO_UPLOADER_ID = 'mobile-logo-uploader';

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Sets the mobile logo to the customer.
         *
         * @param  {Object} file                    The uploader response file.
         * @param  {Object} [customerMobileLogo={}] The mobileCustomization properties.
         * @return {Object} The updated mobileCustomization properties object.
         */
        function addMobileLogo(file, customerMobileLogo) {
            customerMobileLogo = customerMobileLogo || {};

            if (angular.isUndefinedOrEmpty(file) || angular.isUndefinedOrEmpty([file.url, file.blobKey], 'some')) {
                return customerMobileLogo;
            }

            return {
                blobKey: file.blobKey,
                // Manually remove size set by backend response, only for display purposes.
                url: file.url.replace('=s1600', ''),
            };
        }

        /**
         * Check if the mobile logo is valid.
         *
         * @param  {Object}  file The uploader response file.
         * @return {boolean} If the uploaded logo is unvalid.
         */
        function checkMobileLogo(file) {
            if (angular.isUndefinedOrEmpty(file) || angular.isUndefinedOrEmpty([file.url, file.blobKey], 'some')) {
                return true;
            }

            var isFileUnvalid = (file.height !== _MOBILE_LOGO_SPECIFICATIONS.height ||
                file.width > _MOBILE_LOGO_SPECIFICATIONS.width ||
                file.mimeType !== _MOBILE_LOGO_SPECIFICATIONS.type ||
                file.fileSize > _MOBILE_LOGO_SPECIFICATIONS.size);

            if (isFileUnvalid) {
                LxNotificationService.error(Translation.translate('GLOBAL.ERROR_FILE_FORMAT'));
            }

            return isFileUnvalid;
        }

        /////////////////////////////

        service.addMobileLogo = addMobileLogo;
        service.checkMobileLogo = checkMobileLogo;

        /////////////////////////////

        return service;
    }
    /////////////////////////////

    angular.module('Services').service('Mobile', MobileService);
})();
