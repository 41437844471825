(function IIFE() {
    'use strict';

    /////////////////////////////

    function FeaturesPickerController(Features, Translation) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * Translations for the default values.
         *
         * @type {Object}
         */
        vm.DEFAULT_KEYS = {
            'false': Translation.translate('DISABLED'),
            'true': Translation.translate('ENABLED'),
        };

        /**
         * The full features objects.
         *
         * @type {Object}
         */
        vm.FEATURES = Features.getAll();

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            if (angular.isUndefinedOrEmpty(_.get(vm.customer, 'uid'))) {
                return;
            }

            vm.adminMode = vm.adminMode || false;

            vm.features = (vm.adminMode) ? Features.getAll() : Features.getAvailableForCustomer(vm.customer);
            vm.features = _.sortBy(vm.features, 'token');

            Features.initFeaturesStatus(vm.customer);
        }

        init();
    }

    /**
     * A directive that lists the features available for a given customer.
     *
     * @param {boolean} [adminMode] Indicates if we're in adminMode or not. In admin mode, you can see all features,
     *                              whether they are activated for the customer or not.
     * @param {Object}  customer    A customer to list the features of.
     */

    function FeaturesPickerDirective() {
        'ngInject';

        return {
            bindToController: true,
            controller: FeaturesPickerController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                adminMode: '@?',
                customer: '=',
            },
            templateUrl: '/client/back-office/modules/features/views/partials/features-picker.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('featuresPicker', FeaturesPickerDirective);
})();
