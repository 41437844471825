import { toApiV2Language } from '@lumapps/translations';

import { LANGUAGE_CODES_MAPPING } from '../constants';

/**
 * Transform an api v1 language string to an api v2 language string
 * Overload the existing toApiV2Language with additionnal languages, like he
 *
 * `pt_br` will be transformed into `pt-BR`
 * `iw` will be transformed into `he`
 */
export function toStructuredContentApiLanguage(language: string) {
    if (language in LANGUAGE_CODES_MAPPING) {
        return LANGUAGE_CODES_MAPPING[language];
    }
    return toApiV2Language(language);
}
