import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function ContentSidebarDirectoryTemplateController($scope, Directory, FormValidation, LxDialogService,
        LxNotificationService, Translation, Features) {
        'ngInject';

        var directoryTemplateVm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * A map of the different types of components that can be added to the template.
         *
         * @type {Object}
         * @constant
         * @readonly
         */
        directoryTemplateVm.COMPONENT_TYPES = {
            inputSelect: {
                component: 'inputSelect',
                label: 'ADMIN_MODULE_DIRECTORY_COMPONENT_SETTINGS_INPUT_SELECT',
            },
            inputText: {
                component: 'inputText',
                label: 'ADMIN_MODULE_DIRECTORY_COMPONENT_SETTINGS_INPUT_TEXT',
            },
        };

        /**
         * The list of component types.
         *
         * @type {Array}
         * @constant
         * @readonly
         */
        directoryTemplateVm.AVAILABLE_COMPONENT_TYPES = _.values(directoryTemplateVm.COMPONENT_TYPES);

        /**
         * The identifier of the dialog.
         *
         * @type {string}
         * @constant
         * @readonly
         */
        directoryTemplateVm.DIALOG_KEY = 'directory-template-settings';

        /**
         * Contains the directory template forms (moduleDirectory and moduleDirectoryTemplate).
         *
         * @type {Object}
         */
        directoryTemplateVm.form = {};

        /////////////////////////////

        /**
         * Services and utilities.
         */
        directoryTemplateVm.Translation = Translation;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Add a new component to the directory template.
         */
        function addComponent() {
            directoryTemplateVm.activeComponent = {
                availableValues: [{}],
                uuid: generateUUID(),
            };
        }

        /**
         * Close the active component.
         */
        function closeComponent() {
            directoryTemplateVm.activeComponent = undefined;
        }

        /**
         * Close the directory template dialog.
         */
        function closeDialog() {
            LxDialogService.close(directoryTemplateVm.DIALOG_KEY);
        }

        /**
         * Delete a component from the directory template.
         *
         * @param {number} index The index position of the component to delete.
         */
        function deleteComponent(index) {
            LxNotificationService.confirm(
                Translation.translate('ADMIN_MODULE_DIRECTORY_COMPONENT_DELETE'),
                Translation.translate('ADMIN_MODULE_DIRECTORY_COMPONENT_DELETE_DESCRIPTION'), {
                    cancel: Translation.translate('CANCEL'),
                    ok: Translation.translate('OK'),
                }, function onComponentDeleteConfirm(answer) {
                    if (answer) {
                        Directory.getCurrent().template.components.splice(index, 1);
                    }
                });
        }

        /**
         * Edit component.
         *
         * @param {Object} component The component to edit.
         * @param {number} index     The index position of the component to edit.
         */
        function editComponent(component, index) {
            if (angular.isUndefinedOrEmpty(component)) {
                return;
            }

            if (angular.isUndefined(component.properties)) {
                component.properties = {};
            }

            directoryTemplateVm.activeComponent = {
                availableValues: component.properties.availableValues,
                index: index,
                mandatory: component.properties.mandatory,
                multiple: component.properties.multiple,
                textarea: component.properties.textarea,
                title: component.title,
                type: directoryTemplateVm.COMPONENT_TYPES[component.type],
                uuid: component.uuid,
            };
        }

        /**
         * Indicates if the component is invalid.
         *
         * @return {boolean} Whether the component is invalid or not.
         */
        function isComponentInvalid() {
            return angular.isUndefined(_.get(directoryTemplateVm.activeComponent, 'type')) &&
                _.get(directoryTemplateVm.form, 'moduleDirectoryTemplate.$submitted');
        }

        /**
         * Save a new component.
         */
        function saveComponent() {
            var component = angular.fastCopy(directoryTemplateVm.activeComponent);

            if (angular.isDefined([component.title, _.get(component.type, 'component')], 'every') &&
                Translation.hasTranslations(component.title)) {
                var componentToSave = {
                    properties: {
                        availableValues: (angular.isDefinedAndFilled(component.availableValues)) ?
                            component.availableValues : undefined,
                        mandatory: component.mandatory,
                        multiple: component.multiple,
                        textarea: component.textarea,
                    },
                    title: component.title,
                    type: component.type.component,
                    uuid: component.uuid,
                };

                var templateComponents = Directory.getCurrent().template.components;

                if (angular.isUndefined(templateComponents)) {
                    templateComponents = [];
                }

                if (angular.isUndefined(component.index)) {
                    templateComponents.push(componentToSave);
                } else {
                    templateComponents[component.index] = componentToSave;
                }

                directoryTemplateVm.activeComponent = undefined;
            } else {
                if (angular.isDefined(directoryTemplateVm.form.moduleDirectoryTemplate)) {
                    FormValidation.setFormDirty(directoryTemplateVm.form.moduleDirectoryTemplate);
                }

                LxNotificationService.error(Translation.translate('ERROR_CHECK_FORM'));
            }
        }

        /////////////////////////////

        directoryTemplateVm.addComponent = addComponent;
        directoryTemplateVm.closeComponent = closeComponent;
        directoryTemplateVm.closeDialog = closeDialog;
        directoryTemplateVm.deleteComponent = deleteComponent;
        directoryTemplateVm.editComponent = editComponent;
        directoryTemplateVm.isComponentInvalid = isComponentInvalid;
        directoryTemplateVm.saveComponent = saveComponent;

        /////////////////////////////
        //                         //
        //          Events         //
        //                         //
        /////////////////////////////

        /**
         * When the input language changes, reset the forms.
         */
        $scope.$on('inputLanguage', function onInputLanguageChange() {
            if (angular.isDefined(directoryTemplateVm.form.moduleDirectory)) {
                directoryTemplateVm.form.moduleDirectory.$setPristine();
            }

            if (angular.isDefined(directoryTemplateVm.form.moduleDirectoryTemplate)) {
                directoryTemplateVm.form.moduleDirectoryTemplate.$setPristine();
            }
        });
    }

    /////////////////////////////

    angular.module('Controllers').controller('ContentSidebarDirectoryTemplateController',
        ContentSidebarDirectoryTemplateController);
})();
