import { generateUUID } from '@lumapps/utils/string/generateUUID';

(function IIFE() {
    'use strict';

    /////////////////////////////

    function MainNavItemAdminController(Config, ConfigTheme, ContentPicker, LxDialogService, LxNotificationService,
        MainNav, Translation, Utils, UserAccess) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Private attributes   //
        //                         //
        /////////////////////////////

        /**
         * Contains the list of all available modes.
         *
         * @type {Array}
         */
        var _availableMode = [
            'picker',
            'native',
            'pick-nav-items',
        ];

        /**
         * The default mode to use.
         *
         * @type {string}
         */
        var _defaultMode = 'native';

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////

        /**
         * The id of the edit dialog.
         *
         * @type {string}
         */
        vm.editDialogId = 'main-nav-admin-menu-item-edit-dialog';

        /**
         * Indicates if drop is allowed in this nav item.
         *
         * @type {boolean}
         */
        vm.isDropAllowed = false;

        /**
         * Contains the list of picked navigation items.
         *
         * @type {Array}
         */
        vm.pickedNavigationItems = [];

        /**
         * The id of the content picker.
         *
         * @type {string}
         */
        vm.pickerId = 'content-picker-list-key';

        /////////////////////////////

        /*
         * Services and utilities.
         */
        vm.ConfigTheme = ConfigTheme;
        vm.MainNav = MainNav;
        vm.Translation = Translation;
        vm.UserAccess = UserAccess;
        vm.Utils = Utils;

        /////////////////////////////
        //                         //
        //    Private functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if the item is editable.
         *
         * @return {boolean} If the item is editable or not.
         */
        function _isItemEditable() {
            // If native mode, allow each entry to be edited else check if is in the right mode.
            return (vm.mode === 'native' || vm.item.mode === vm.mode ||
                _.includes(MainNav.getEditableContentIds(), vm.item.id)) && vm.mode !== 'pick-nav-items';
        }

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Check if we can redirect the user to the content of the given nav item.
         *
         * @param  {Object}  item The item we want to check if the user can be redirected.
         * @return {boolean} If the user can be redirected to the content or not.
         */
        function canView(item) {
            return Config.AVAILABLE_CONTENT_TYPES.MENU !== item.type;
        }

        /**
         * Check the item and toggle it from the picked items list.
         *
         * @param {Object} item The item we want to check and toggle.
         */
        function checkItem(item) {
            var indexOfItem = vm.pickedItems.indexOf(item.uuid);
            if (indexOfItem > -1) {
                vm.pickedItems.splice(indexOfItem, 1);
            } else {
                vm.pickedItems.push(item.uuid);
            }
        }

        /**
         * Copy an element on drop.
         *
         * @param  {number}  index The index where the element as been dropped (and must be copied).
         * @param  {Object}  item  The item to copy.
         * @return {boolean} If the element has been copied or not.
         */
        function copiedItem(index, item) {
            if (MainNav.isItemDragged()) {
                return false;
            }

            if (vm.mode === 'picker' && !vm.isDropAllowed) {
                MainNav.setItemIsDragged(true);
                LxNotificationService.warning(Translation.translate('ADMIN.MAIN_NAV.ERROR_NOT_ALLOWED'));

                return false;
            }

            return MainNav.copyElement(index, item, vm.item, function onCopyElementError() {
                LxNotificationService.warning(Translation.translate('ADMIN.MAIN_NAV.ERROR_NOT_UNIQUE'));
            });
        }

        /**
         * Edit an item.
         *
         * @param {Object} item The item to edit.
         */
        function editItem(item) {
            MainNav.setOriginalItem(item);
            MainNav.setEditedItem(angular.fastCopy(item));

            if (vm.mode === 'native') {
                Utils.waitForAndExecute('#' + vm.editDialogId);
            }
        }

        /**
         * Check if the item is already picked.
         *
         * @param  {Object}  item The item to check.
         * @return {boolean} If the item is already picked or not.
         */
        function isPicked(item) {
            return _.includes(vm.pickedItems, item.uuid);
        }

        /**
         * Splice item from parent and update parent id.
         */
        function moveItem() {
            vm.parent.children.splice(vm.index, 1);

            MainNav.updateSortOrder(vm.parent.children);
            MainNav.addModifiedElements(vm.parent.children);
        }

        /**
         * Open the content picker to pick a content for the given target nav item.
         *
         * @param {Object} target The target nav item in which to pick contents.
         */
        function openContentPicker(target) {
            MainNav.setCurrentPickerTarget(target);
            /**
             * Since we have two versions of the main nav admin living together (angular and react),
             * switching between both can sometimes cause nodes to appear as content with different id formats.
             * The picker will try to fetch these ids and fail, breaking the whole picker and nav.
             *
             * To avoid that, we need to remove any object with these id formats so that the picker
             * doesn't try to fetch them.
             */
            const contentWithIds = vm.item.children.filter((item) => !MainNav.itemIsHybrid(item));
            MainNav.setAlreadyPickedContents(contentWithIds);

            Utils.waitForAndExecute('#' + vm.pickerId, ContentPicker);
        }

        /**
         * Remove item from nav management list.
         */
        function removeItem() {
            MainNav.addDeletedElement(vm.item, Translation.inputLanguage);

            if (MainNav.isItemInEdition()) {
                MainNav.clearEditItem();
            }
        }

        /**
         * Restore previously deleted nav item.
         */
        function restoreItem() {
            MainNav.restoreElement(vm.item);
        }

        /**
         * Toggle children.
         */
        function toggleChildren() {
            vm.displaychildren = !vm.displaychildren;
        }

        /////////////////////////////

        vm.canView = canView;
        vm.checkItem = checkItem;
        vm.copiedItem = copiedItem;
        vm.editItem = editItem;
        vm.isPicked = isPicked;
        vm.moveItem = moveItem;
        vm.openContentPicker = openContentPicker;
        vm.removeItem = removeItem;
        vm.restoreItem = restoreItem;
        vm.toggleChildren = toggleChildren;

        /////////////////////////////

        /**
         * Initialize the controller.
         */
        function init() {
            vm.mode = (_.includes(_availableMode, vm.mode)) ? vm.mode : _defaultMode;
            vm.item.mode = vm.item.mode || _defaultMode;

            // If is falsy but not false set it a true.
            vm.editable = _isItemEditable();

            vm.displaychildren = false;

            vm.item.isNavItem = true;
            vm.item.parentId = vm.parent.id;
            vm.item.parentUuid = vm.parent.uuid;

            if (angular.isDefined(vm.pickedItems)) {
                vm.pickedNavigationItems = vm.pickedItems;
            }

            if (angular.isUndefinedOrEmpty(vm.item.uuid)) {
                vm.item.uuid = generateUUID();
            }

            if (angular.isUndefined(vm.item.hidden)) {
                vm.item.hidden = false;
            }

            if (angular.isUndefined(vm.item.newTab)) {
                vm.item.newTab = false;
            }

            if (angular.isUndefined(vm.item.children)) {
                vm.item.children = [];
            }

            vm.isDropAllowed = UserAccess.isUserAllowed('MENU_DROP', {
                uuid: vm.item.uuid,
            });
        }

        init();
    }

    /////////////////////////////

    function MainNavItemAdminDirective(RecursionHelper) {
        'ngInject';

        function compile(el) {
            return RecursionHelper.compile(el);
        }

        return {
            bindToController: true,
            compile: compile,
            controller: MainNavItemAdminController,
            controllerAs: 'vm',
            replace: true,
            restrict: 'E',
            scope: {
                index: '=lsIndex',
                item: '=lsItem',
                mode: '@lsMode',
                parent: '=lsParent',
                pickedItems: '=lsPickedItems',
            },
            templateUrl: '/client/back-office/modules/main-nav/views/main-nav-item-admin.html',
        };
    }

    /////////////////////////////

    angular.module('Directives').directive('mainNavItemAdmin', MainNavItemAdminDirective);
})();
