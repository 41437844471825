import get from 'lodash/get';

function MediaAdminController(
    $state,
    AbstractPicker,
    DocumentProvider,
    Features,
    MediaConstant,
    Utils,
) {
    'ngInject';

    const vm = this;

    /////////////////////////////
    //                         //
    //    Public attributes    //
    //                         //
    /////////////////////////////

    /**
     * The view to display when picker opens.
     * If not set, opens default view.
     *
     * @type {string}
     */
    vm.filterView = undefined;

    /**
     * The list key for the media list.
     *
     * @type {string}
     * @constant
     * @readonly
     */
    vm.listKey = 'media-admin';

    /////////////////////////////

    /**
     * Services and utilities.
     */
    vm.MediaConstant = MediaConstant;

    /////////////////////////////

    /**
     * Initialize the controller.
     */
    async function init() {
        const providerId = 'local';
        const categoryId = $state.current.name === 'app.admin.media_trash' ? 'TRASH' : 'LIBRARY';
        const category = await DocumentProvider.getProviderCategory(providerId, categoryId);

        vm.filterView = {
            categoryId,
            providerId,
            searchParameters: get(category, 'properties', {}),
        };

        Utils.waitForAndExecute(`#${vm.listKey}`, AbstractPicker, false, undefined, undefined, true);
    }

    init();
}

/////////////////////////////

angular.module('Controllers').controller('MediaAdminController', MediaAdminController);

export { MediaAdminController };
