/* istanbul ignore file */

import BaseApi from '@lumapps/base-api';

import { STRUCTURED_CONTENT_FORMAT, StructuredContent } from '../types';

/**
 * Structured content API.
 */
export const structuredContentApi = new BaseApi({ version: BaseApi.versions.v2, path: 'structured-content' });

/** Create a structured content (POST)
 * @see https://lumapps-api.stoplight.io/explore/lumapps-private-api/6119f0aabe00b-create-a-structured-content-entity
 */
export const createStructuredContent = async (
    translatedContent: StructuredContent['dita']['translations'] | StructuredContent['richText']['translations'],
    format?: STRUCTURED_CONTENT_FORMAT,
) => {
    const { data } = await structuredContentApi.post<StructuredContent>(
        '',
        {
            translations: translatedContent,
        },
        { params: { format } },
    );
    return data;
};

/** Get structured content by ID (GET)
 * @see https://lumapps-api.stoplight.io/explore/lumapps-private-api/c13c77726a9aa-retrieve-a-structured-content-entity
 */
export const getStructuredContentById = async (structuredContentId: string) => {
    const { data } = await structuredContentApi.get<StructuredContent>(structuredContentId);
    return data;
};

/** Update a structured content (PUT)
 * @see https://lumapps-api.stoplight.io/explore/lumapps-private-api/401ef57bd9ac6-update-a-structured-content-entity
 */
export const updateStructuredContent = async (
    structuredContentId: string,
    translatedContent: StructuredContent['dita']['translations'] | StructuredContent['richText']['translations'],
    format?: STRUCTURED_CONTENT_FORMAT,
) => {
    const { data } = await structuredContentApi.put<StructuredContent>(
        structuredContentId,
        {
            translations: translatedContent,
        },
        { params: { format } },
    );
    return data;
};
