/**
 * Map of all Lumapps supported identity providers.
 *
 * @type {Object}
 * @constant
 * @readonly
 */
const IdentityProvidersConstant = {
    BATCH_PROVISIONING: 'batch_provisioning',
    EXTERNAL: 'email',
    GOOGLE: 'google',
    MICROSOFT: 'microsoft',
    OKTA: 'okta',
    SAML: 'saml',
};
/////////////////////////////

angular.module('Constants').constant('IdentityProvidersConstant', IdentityProvidersConstant);

/////////////////////////////

export { IdentityProvidersConstant };
