function CustomerPropertiesService(Customer, CustomerPropertiesFactory) {
    'ngInject';

    // eslint-disable-next-line consistent-this
    const service = {};

    /////////////////////////////
    //                         //
    //     Public functions    //
    //                         //
    /////////////////////////////

    /**
     * Save the customer properties.
     *
     * @return {Promise} The save promise.
     */
    function save() {
        return CustomerPropertiesFactory.save({
            properties: Customer.getCustomer().properties,
        }).$promise;
    }

    /////////////////////////////

    service.save = save;

    /////////////////////////////

    return service;
}

/////////////////////////////

angular.module('Services').service('CustomerProperties', CustomerPropertiesService);

/////////////////////////////

export { CustomerPropertiesService };
