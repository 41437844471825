import React from 'react';

import uniqueId from 'lodash/uniqueId';

import { extended } from '@lumapps/notifications';
import { actions as notificationActions } from '@lumapps/notifications/ducks/slice';
import type { Dispatch } from '@lumapps/redux/types';
import type { Route } from '@lumapps/router';

import {
    NotificationActions,
    NotificationContent,
    NotificationFigure,
} from '../components/DistributeContentPublishedNotification';

const DOMAIN = 'publishedContentDistributeNotification';

/**
 * Thunk used in legacy designer to display an extended notification when content is created
 * The extended notification includes a link open distribute content form in FO
 */
export const showContentPublishedDistributeNotfication =
    (notificationTitle: string, contentRoute: Route) => async (dispatch: Dispatch) => {
        const notificationId = uniqueId(DOMAIN);

        const options = {
            id: notificationId,
            title: notificationTitle,
            figure: <NotificationFigure />,
            content: <NotificationContent />,
            actions: (
                <NotificationActions
                    route={contentRoute}
                    // when clicking the link, we also want to hide THIS notification
                    onClick={() => dispatch(notificationActions.hide({ id: notificationId }))}
                />
            ),
        };

        dispatch(extended(options));
    };
