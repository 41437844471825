/* istanbul ignore file */
import { Route } from '@lumapps/router';
import { AppId } from '@lumapps/router/constants';

const rootAdminRoute: Route = {
    path: '/admin',
    legacyId: 'app.admin',
    appId: AppId.backOffice,
};

const instanceSettingsAdminRoute: Route = {
    path: '/admin/instance-settings',
    legacyId: 'app.admin.instance-settings',
    appId: AppId.legacy,
};

/**
 * Retrieves the route for main nav's admin.
 */
const instanceSettings = (): Route => instanceSettingsAdminRoute;
const rootAdmin = (): Route => rootAdminRoute;

export { instanceSettings, instanceSettingsAdminRoute, rootAdmin, rootAdminRoute };
