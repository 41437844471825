(function IIFE() {
    'use strict';

    /////////////////////////////

    function DuplicateContentController($location, $window, Content, Customer, Instance, User) {
        'ngInject';

        var vm = this;

        /////////////////////////////
        //                         //
        //    Public attributes    //
        //                         //
        /////////////////////////////


        /////////////////////////////

        /*
         * Services and utilities.
         */
        vm.Instance = Instance;

        /////////////////////////////
        //                         //
        //     Public functions    //
        //                         //
        /////////////////////////////

        /**
         * Refresh the instance list for the dropdown.
         */
        function loadInstances() {
            var superAdminInstances = _.get(User.getConnected(), 'instancesSuperAdmin');
            if (angular.isUndefinedOrEmpty(superAdminInstances)) {
                return;
            }

            Instance.filterize({
                ids: superAdminInstances,
            }, undefined, undefined, 'duplicate-content-list');
        }

        /**
         * Duplicate the current content in the given instance.
         *
         * @param {Object} instance The instance to duplicate to content to.
         */
        function duplicateContent(instance) {
            if (angular.isUndefinedOrEmpty([_.get(instance, 'slug'), _.get(instance, 'id')], 'some')) {
                return;
            }

            var currentContentId = _.get(Content.getCurrent(), 'id');
            if (angular.isUndefinedOrEmpty(currentContentId)) {
                return;
            }

            var customerSlug = Customer.getCustomerSlug();
            var customerSlugUrlPart = '/a/' + customerSlug + '/';
            if (_.includes($location.path(), customerSlugUrlPart)) {
                $window.open(
                    customerSlugUrlPart + instance.slug + '/content/duplicate/' + currentContentId +
                        '?instanceId=' + instance.id,
                    '_blank'
                );
            } else {
                $window.open(
                    '/' + instance.slug + '/content/duplicate/' + currentContentId + '?instanceId=' + instance.id,
                    '_blank'
                );
            }
        }

        /////////////////////////////

        vm.duplicateContent = duplicateContent;
        vm.loadInstances = loadInstances;
    }

    /////////////////////////////

    angular.module('Controllers').controller('DuplicateContentController', DuplicateContentController);
})();
